import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../user/user';
import { NotificationProvider } from '../notification/notification';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {

    constructor(
        private readonly user: User, 
        private readonly notification: NotificationProvider,
        private readonly translate: TranslateService
        ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 || error.status === 403) {
                    this.user.logout();
                    this.notification.presentToast(this.translate.instant('General.Error.SessionExpired'));
                }
                if(error.status === 0 || error.status >= 500)
                {
                    this.notification.presentToast(this.translate.instant('General.Error.ServerError'));
                }
                return throwError(error)
            })
        )
    }
}