import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Api } from '../api/api';
import { User } from '../user/user';
import { Appointment } from '../../models';
import { GroupTherapyData } from 'src/app/models/api/group-therapy-data.model';
import { GroupTherapyPatient } from 'src/app/models/api/group-therapy-patient.model';

@Injectable()
export class GroupTherapyProvider {
  public badgeCount = 0;

  constructor(
    public api: Api,
    private user: User
  ) { }

  /**
   * Initialize the appointment list.
   */
  public init(): void {
    this.loadGroupTherapyAppointments().subscribe(
      () => {
      },
      (err) => console.error(err)
    );
  }

  /**
   * Load the appointment list by sending a request.
   *
   * @returns     Http-Response is the list of appointments
   */
  public loadGroupTherapyAppointments(): Observable<GroupTherapyData> {
    return this.api.get<GroupTherapyData>('groupTherapy/getDataByAccountId', {accountId: this.user.user!.account.id}, {}).pipe(
      tap((res: GroupTherapyData) => {
        res.appointments.map(appointment=>{
          //api return patients as array of ids, application and POST use as string seperated ids by comma
          appointment.patients = (<string[]>(appointment.patients as unknown)).join(',');
        });
        res.appointments.sort((a, b) => (a.date > b.date) ? 1 : -1)
        if (!res) {
          console.error('No appointment data retrieved', res);
        }
      })
    );
  }

  /**
   * Send request to create a new appointment.
   * @param appointment   The appointment to create
   * @returns         Http-Response is the created appointment
   */
  public addAppointment(appointment: Appointment, itemId: string): any {
    return this.api.post('event/createEventSerieForGroupTherapy', { appointment,
      itemId: itemId,
      medicalId : appointment.medicalId }).pipe(
      tap((res) => {
        if (res) {
          // Add patient on client
            //console.log(res);
        } else {
          console.error('No appointment data retrieved', res);
        }
      })
    );
  }

  /**
   * Send request to update an appointment.
   * @param appointment   The appointment to update
   * @param isDelete
   * @returns         Http-Response is the updated appointment
   */
  public updateAppointment(appointment: Appointment, isDelete = false): Observable<any> {
    return this.api.post('event/updateEventSerieForGroupTherapy', { appointment,
      itemId: '60e69fc9d263307bb',
      isDelete: isDelete,
      medicalId : this.user.user!.id }).pipe(
      tap((res) => {
        if (res) {

        } else {
          console.error('No appointment data retrieved', res);
        }
      })
    );
  }

  /**
   * Send request to close a therapy session.
   * @param appointmentId   The appointment to update
   * @returns         OK
   */
  public closeTherapySession(appointmentId: number, patientList: string): Observable<any> {
    return this.api.post('groupTherapy/confirmGroupTherapy', { appointmentId, patients: patientList }).pipe(
      tap((res) => {
        if (res) {

        } else {
          console.error('No appointment data retrieved', res);
        }
      })
    );
  }

  /**
   * Send request to get a list of group therapy participants.
   * @param appointmentId   The appointment to get participants from
   * @returns         Http-Response is the updated appointment
   */
  public getPatientsForAppointment(appointmentId: number): Observable<GroupTherapyPatient[]> {
    return this.api.get<GroupTherapyPatient[]>('groupTherapy/getPatientsForAppointment', {appointmentId}, {}).pipe(
      tap((res) => {
        if (res) {

        }
        else {
          console.error('No appointment data retrieved', res);
        }
      })
    );
  }

  /**
   * Send request to update an appointment.
   *
   * @param appointment   The appointment to update
   * @returns         Http-Response is the updated appointment
   */
  public checkGroupTherapyToday(): Observable<Appointment[]> {
    return this.api.get<any[]>('appointment/getAppointmentByMedicalIdForToday', {medicalId : this.user.user!.id}, {}).pipe(
      tap((res) => {
        if (res) {
            return res;
        }
        else {
          console.error('No appointment data retrieved', res);
        }
      })
    );
  }

  public getGroupTherapyLink(appointmentId: string, participantId: string): Observable<string> {
    return this.api.get<string>('groupTherapy/getGroupTherapyLinkFromMedicalSyn', {appointmentId, participantId}, {}).pipe(
      tap((res) => {
        if (res) {

        }
        else {
          console.error('No link retrieved', res);
        }
      })
    );
  }
}
