import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Api } from '../api/api';
import { NeuroResult } from "../../models/neuro-result.model";

@Injectable({
  providedIn: 'root'
})
export class NeuronationProvider {

  private neuroResults$$: BehaviorSubject<NeuroResult[]> = new BehaviorSubject<NeuroResult[]>([]);
  public neuroResults$: Observable<NeuroResult[]> = this.neuroResults$$.asObservable();

  constructor(
    private api: Api
  ) { }

  public fetchNeuroResultsForEvent(eventId: string) {
    this.api.get('neuroNation/getNeuroResultsByEvent', { eventId: eventId}).subscribe((neuroResults: NeuroResult[]) => {
        this.neuroResults$$.next(neuroResults);
    })
  }

  public getNeuroResultsForEvent(): Observable<NeuroResult[]> {
    return this.neuroResults$;
  }

  public clear(): void {
    this.neuroResults$$.next([]);
  }

}
