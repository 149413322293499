import { NgModule } from '@angular/core';
import { LocaleDatePipe } from './locale-date/locale-date';
import { MinuteSecondsPipe } from './minute-seconds/minute-seconds';
import { HourMinuteSecondsPipe } from "./hour-minute-seconds/hour-minute-seconds";
import { LocaleDateTimePipe } from "./locale-date-time/locale-date-time";
import { SecondsPipe } from "./seconds/seconds";
import {LocaleDateTimeUnixPipe} from "./locale-date-time-unix/locale-date-time-unix";

@NgModule({
	declarations: [LocaleDatePipe, MinuteSecondsPipe, HourMinuteSecondsPipe, LocaleDateTimePipe, SecondsPipe, LocaleDateTimeUnixPipe],
	imports: [],
	exports: [LocaleDatePipe, MinuteSecondsPipe, HourMinuteSecondsPipe, LocaleDateTimePipe, SecondsPipe, LocaleDateTimeUnixPipe]
})
export class PipesModule {}
