import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'catalogue',
    loadChildren: () => import('./catalogue/catalogue.module').then( m => m.CataloguePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'catalogue-item-preview',
    loadChildren: () => import('./catalogue-item-preview/catalogue-item-preview.module').then( m => m.CatalogueItemPreviewPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'confirmation-dialog',
    loadChildren: () => import('./confirmation-dialog/confirmation-dialog.module').then( m => m.ConfirmationDialogPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'event-create',
    loadChildren: () => import('./event-create/event-create.module').then( m => m.EventCreatePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'event-information-modal',
    loadChildren: () => import('./event-information-modal/event-information-modal.module').then( m => m.EventInformationModalPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'list-master',
    loadChildren: () => import('./list-master/list-master.module').then( m => m.ListMasterPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'neuronation',
    loadChildren: () => import('./neuronation/neuronation.module').then( m => m.NeuronationPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'package-catalogue',
    loadChildren: () => import('./package-catalogue/package-catalogue.module').then( m => m.PackageCataloguePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'package-item-preview',
    loadChildren: () => import('./package-item-preview/package-item-preview.module').then( m => m.PackageItemPreviewPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'therapy-unit-detail',
    loadChildren: () => import('./therapy-unit-detail/therapy-unit-detail.module').then( m => m.TherapyUnitDetailPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'videocall',
    loadChildren: () => import('./videocall/videocall.module').then( m => m.VideocallPageModule),
    canActivate: [AuthGuardService]
  },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'group-therapy-add-appointment',
    loadChildren: () => import('./group-therapy-add-appointment/group-therapy-add-appointment.module')
                        .then( m => m.GroupTherapyAddAppointmentPageModule)
  },
  {
    path: 'group-therapy-add-medical',
    loadChildren: () => import('./group-therapy-add-medical/group-therapy-add-medical.module')
                        .then( m => m.GroupTherapyAddMedicalPageModule)
  },
  {
    path: 'group-therapy-add-patient',
    loadChildren: () => import('./group-therapy-add-patient/group-therapy-add-patient.module')
                        .then( m => m.GroupTherapyAddPatientPageModule)
  },
  {
    path: 'group-therapy-confirm-session',
    loadChildren: () => import('./group-therapy-confirm-session/group-therapy-confirm-session.module')
                        .then( m => m.GroupTherapyConfirmSessionPageModule)
  },
  {
    path: 'group-therapy-list',
    loadChildren: () => import('./group-therapy-list/group-therapy-list.module')
                      .then( m => m.GroupTherapyListPageModule)
  },
  {
    path: 'neuronation-exercises',
    loadChildren: () => import('./neuronation-exercises/neuronation-exercises.module')
      .then( m => m.NeuronationExercisesPageModule)
  },
  {
    path: 'neuronation-infos',
    loadChildren: () => import('./neuronation-infos/neuronation-infos.module')
      .then( m => m.NeuronationInfosPageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
