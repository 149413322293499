import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ModalController} from '@ionic/angular';
import * as moment from 'moment';
import {EventCreatePage} from '../../event-create/event-create.page';
import {EventInformationModalPage} from '../../event-information-modal/event-information-modal.page';
import {ConfirmationDialogPage} from '../../confirmation-dialog/confirmation-dialog.page';
import {Api} from '../../services/api/api';
import {EventsProvider, LoadingProvider, NotificationProvider} from '../../services';
import {CalendarItem, Event, Patient} from '../../models';
import {EventCalendarHeaderComponent} from '../event-calendar-header/event-calendar-header';
import {MatCalendar, MatCalendarCellClassFunction} from '@angular/material/datepicker';
import {TranslateService} from '@ngx-translate/core';
import {EditMode} from '../../models/edit-mode';
import {Duration} from "../../services/duration/duration";

@Component({
  selector: 'event-calendar',
  templateUrl: 'event-calendar.html',
  styleUrls: ['event-calendar.scss']
})
export class EventCalendarComponent implements OnDestroy, AfterViewInit {

  eventCalendarHeader = EventCalendarHeaderComponent;

  @ViewChild('calendar') calendar!: MatCalendar<moment.Moment>;

  @Input()
  public patient!: Patient;

  @Output()
  public setSelectedDate: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();

  /** Notifier for unsubscribing all subscriptions on leaving the page. */
  private unsubscribe$ = new Subject<void>();
  /** Flag to disable the delete button until previous delete call is finished. */
  public isButtonDisabled = false;
  /** The source for the ionic calendar. */
  public eventSource: CalendarItem[] = [];
  /** Title of the calendar set to the current month. */
  public viewTitle = '';
  /** The selected day in the calendar. */
  public selectedDay: moment.Moment | null = moment();
  /** Calendar items for selected day */
  public selectedCalendarItems: CalendarItem[] = [];

  public chosenDate: string | undefined = this.selectedDay?.format();

  public sessionDuration: number = 0;

  public countableEvents: number = 0;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly eventsProvider: EventsProvider,
    private readonly notification: NotificationProvider,
    private readonly loading: LoadingProvider,
    private readonly api: Api,
    private readonly translate: TranslateService,
    private readonly duration: Duration
  ) { }

  ngAfterViewInit(): void {
    this.loading.presentLoading(this.translate.instant('Calendar.Loading'));
    this.eventsProvider.loadEventSourceByPatientId(this.patient.id);
    this.eventsProvider.getEventSource()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (eventSource: CalendarItem[]) => {
          if (eventSource) {
            this.eventSource = eventSource;
            if(this.selectedDay) {
              this.onEventSelected(this.selectedDay);
            }
          } else {
            this.eventSource = [];
          }
          this.calendar.updateTodaysDate();
          this.loading.dismissLoading();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.eventsProvider.emptyEventSource();
  }

  dateClass: MatCalendarCellClassFunction<moment.Moment> = (cellDate: moment.Moment, view) => {
    if (cellDate.day() === 0) {
      return 'holiday';
    }
    for (const event of this.eventSource) {
      if(event.startTime.getFullYear() === cellDate.year() &&
        event.startTime.getMonth() === cellDate.month() &&
        event.startTime.getDate() === cellDate.date()) {
        return 'event';
      }
    }
    return '';
  };

  /**
   * Emit event to parent page, so that parent page can disable add button when selected date is in the past.
   *
   * @param date
   */
  public onEventSelected(date: moment.Moment | null): void {
    if(!date) {
      return;
    }
    this.countableEvents = 0;
    this.sessionDuration = 0;
    this.setSelectedDate.emit(date);
    this.chosenDate = this.selectedDay?.format();
    this.selectedCalendarItems = this.eventSource
      .filter((c: CalendarItem) => (
        new Date(c.startTime).toISOString().substring(0,10) === date.format('YYYY-MM-DD'))
      );
    this.selectedCalendarItems.map(async (cI: CalendarItem) => {
      if(!cI.event.eventSerie?.item) {
        return;
      }
      if (cI.event.eventSerie.item.treatment.name === 'Grouptherapy') {
        if (this.chosenDate) {
          let tDate = this.chosenDate.split('T')[0];
            if (cI.event?.eventSerie?.targetdate) {
              if (cI.event?.eventSerie?.targetdate === tDate) {
                let tDuration = await this.duration.getDuration(cI.event);
                cI.audioDuration = tDuration;
                cI.showGroupTherapy = true;
                this.sessionDuration += tDuration;
                this.countableEvents++;
              }
            }
        }
      } else {
        let tDuration = await this.duration.getDuration(cI.event);
        cI.audioDuration = tDuration;
        this.sessionDuration += tDuration;
        this.countableEvents++;
      }

    });
  }

  /**
   * Changes the title at the top of the calendar (i.e. the current month).
   *
   * @param title   The new title
   */
  public onViewTitleChanged(title: string): void {
    this.viewTitle = title;
  }

  /**
   * Checks, if the info button should be disabled for the calendar item.
   *
   * @param calItem   The calendar item that has its info button enabled or disabled
   * @returns         True, if the info button should be disalbed
   */
  public isInfoDisabled(calItem: CalendarItem): boolean {
    return this.isButtonDisabled || (!calItem.event.eventSerie?.infos
      && (!calItem.event.eventSerieSnapshot || !calItem.event.eventSerieSnapshot.infos));
  }

  /**
   * Checks, if the edit button should be disabled for the calendar item.
   *
   * @param calItem   The calendar item that has its edit button enabled or disabled
   * @returns         True, if the edit button should be disabled
   */
  public isEditDisabled(calItem: CalendarItem): boolean {
    return this.isButtonDisabled || calItem.event.eventSerie
      && moment(calItem.event.eventSerie.enddate).isBefore(moment(), 'day') || calItem.event.executed > 0;
  }

  /**
   * Checks, if the delete button should be disabled for the calendar item.
   *
   * @param calItem   The calendar item that has its delete button enabled or disabled
   * @returns         True, if the delete button should be disabled
   */
  public isDeleteDisabled(calItem: CalendarItem): boolean {
    return this.isButtonDisabled || moment(calItem.event.date).isBefore(moment(), 'day') || calItem.event.executed > 0;
  }

  /**
   * Opens the page "EventEditPage" in a modal for editing the event series.
   *
   * @param calItem   The selected calendar item that has its event series edited
   */
  async editEventSeries(calItem: CalendarItem) {
    this.isButtonDisabled = true;
    const eventSeries = Object.assign({}, calItem.event.eventSerie);
    const modal = await this.modalCtrl.create({
      component: EventCreatePage,
      componentProps: {
        eventSeries,
        events: eventSeries ? this.eventsProvider.getEventsOfEventSeries(eventSeries) : [],
        editMode: EditMode.edit,
        patient: this.patient
      }
    });
    modal.onDidDismiss()
      .then((data) => {
        if (data.data) {
          data.data.patient = this.patient;
          this.eventsProvider.updateEventSeries(data.data).subscribe(
            () => {
              this.isButtonDisabled = false;
            },
            (err) => {
              this.notification.presentToast(this.translate.instant('Event.Error.Update'));
              this.isButtonDisabled = false;
              console.error(err);
            }
          );
        } else {
          this.isButtonDisabled = false;
        }
      });
    return await modal.present();
  }

 /**
  * Opens the page "EventExtendPage" in a modal for a new prescription of old event series.
  *
  * @param calItem   The selected calendar item that has its event series inserted for copy
  */
  async extendEventSeries(calItem: CalendarItem) {
    this.isButtonDisabled = true;
    const eventSeries = Object.assign({}, calItem.event.eventSerie);
    const modal = await this.modalCtrl.create({
      component: EventCreatePage,
      componentProps: {
        eventSeries,
        events: eventSeries ? this.eventsProvider.getEventsOfEventSeries(eventSeries) : [],
        editMode: EditMode.extend
      }
    });
   modal.onDidDismiss()
     .then((data) => {
       if (data.data) {
         data.data.patient = this.patient;
         this.eventsProvider.addEventSeries(data.data).subscribe(
           () => {
             this.isButtonDisabled = false;
           },
           (err) => {
             this.notification.presentToast(this.translate.instant('Event.Error.Save'));
             this.isButtonDisabled = false;
             console.error(err);
           }
         );
       } else {
         this.isButtonDisabled = false;
       }
     });
   return await modal.present();
  }

 /**
  * Delete an event from its event series.
  *
  * @param calItem   The selected calendar item that has its event deleted
  */
  async deleteEvent(calItem: CalendarItem) {
    this.isButtonDisabled = true;
    const modal = await this.modalCtrl.create({
      component: ConfirmationDialogPage,
      cssClass: 'confirmation-modal',
      componentProps: {
        text: 'Event.Delete',
      }
    });
   modal.onDidDismiss()
     .then((data) => {
       if (data.data) {
         this.eventsProvider.deleteEvent(calItem.event).subscribe(
           () => this.isButtonDisabled = false,
           (err) => {
             this.notification.presentToast(this.translate.instant('Event.Error.Delete'));
             this.isButtonDisabled = false;
             console.error(err);
           }
         );
       } else {
         this.isButtonDisabled = false;
       }
     });
   return await modal.present();
  }

  /**
   * Opens Information Modal to display information from specific event.
   *
   * @param information the information to be displayed in modal.
   */
  async openInformationModal(information: string) {
    const modal = await this.modalCtrl.create({
      component: EventInformationModalPage,
      cssClass: 'info-modal',
      componentProps: {
        infos: information,
      }
    });
    void modal.onDidDismiss();
    return await modal.present();
  }

  totalDuration(event: Event): number {

    if (event.eventSerie?.item?.treatment?.name !== "Survey" && event.eventSerie?.item?.treatment?.name !== "Grouptherapy" && event.eventSerie?.item?.treatment?.name !== "Feed") {
      const configCountSets = event.eventSerieSnapshot?.configCountSets || event.eventSerie?.configCountSets || 0;
      if(configCountSets === 0) {
        return configCountSets;
      }
      const perDay = event.eventSerieSnapshot?.perDay || event.eventSerie?.perDay || 0;
      const configCountReps = event.eventSerieSnapshot?.configCountReps || event.eventSerie?.configCountReps || 0;
      const configDuration = event.eventSerieSnapshot?.configDuration || event.eventSerie?.configDuration || 0;
      const configTimeBetweenSets = event.eventSerieSnapshot?.configTimeBetweenSets || event.eventSerie?.configTimeBetweenSets || 0;
      return ((configCountSets * configCountReps * configDuration) + (configCountSets - 1) * configTimeBetweenSets) * perDay;
    } else {
      return event.eventSerieSnapshot?.configDuration || event.eventSerie?.configDuration || 0;
    }
  }
}
