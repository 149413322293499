import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { GroupTherapyProvider } from '../services';
import { GroupTherapyConfirmSessionPage } from '../group-therapy-confirm-session/group-therapy-confirm-session.page';
import { NotificationProvider } from '../services';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generated class for the VideocallPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-videocall',
  templateUrl: 'videocall.page.html',
  styleUrls: ['./videocall.page.scss']
})
export class VideocallPage {

  public videoLink: SafeResourceUrl = '';
  public isVideoActive = false;
  private readonly appointmentId!: string;

  constructor(
    private readonly navCtrl: NavController,
    private readonly domSani: DomSanitizer,
    private readonly modalCtrl: ModalController,
    private readonly notification: NotificationProvider,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {
    const state = this.router.getCurrentNavigation()?.extras.state;
    if (state) {
      this.videoLink = this.domSani.bypassSecurityTrustResourceUrl(state.videoLink);
      this.appointmentId = state.appointmentId;
      this.isVideoActive = true;
    }
  }

  async closeTherapy() {
    const modal = await this.modalCtrl.create({
      component: GroupTherapyConfirmSessionPage,
      componentProps: {
        appointmentId: this.appointmentId
      }
    });
    modal.onDidDismiss()
      .then((data => {
        if (data.data !== undefined) {
          this.isVideoActive = false;
          this.notification.presentToast(this.translate.instant('VideoCall.Closed'));
          this.router.navigate(['tabs/group-therapy-list']);
        }
      })
      );
    return await modal.present();
  }

  goBack() {
    void this.navCtrl.pop();
  }

}

