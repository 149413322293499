import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationProvider {

  constructor(
    private toastCtrl: ToastController
  ) { }

  /**
   * Show a toast with a specified information to the user.
   *
   * @param text  The message being shown in the toast
   * @param dur   Optional duration for showing the toast (default 5s)
   */
  public presentToast(text: string, dur?: number) {
    const toastData: any = {
      message: text,
      duration: dur || 5000,
      position: 'top',
      cssClass: 'toast-notification'
    };
    void this.showToast(toastData);
  }

  /**
   * Remove the current toast.
   */
  public dismissToast(): void {
    if (this.toastCtrl) {
      void this.toastCtrl.dismiss();
    }
  }

  /**
   * Creates and presents a toast.
   *
   * @param data  The message and configurations of the toast
   */
  async showToast(data: any) {
    const toast = await this.toastCtrl.create({
      message: data.message,
      position: 'top',
      duration: 3000,
      buttons: [
        {
          text: 'O.k.',
          role: 'cancel',
          handler: () => {

          }
        }
      ]
    });
    await toast.present();

    await toast.onDidDismiss();
  }

}
