import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from "@angular/router";
import { Event, Patient } from "../models";
import { ApiNeuronation, LoadingProvider } from "../services";
import { NeuronationProvider } from "../services/neuronation/neuronation";
import {Subscription, zip} from "rxjs";
import { NeuroResult } from "../models/neuro-result.model";
import { Location } from "@angular/common";
import exercises from '../../assets/json/exercises_dev.json';
import {isArray} from "lodash";
import {User} from "../services/user/user";
interface innerContent {
    workoutId: string,
    cycleId: any,
    createdAt: any,
    level: any,
    exerciseId: any,
    startDifficulty: any,
    endDifficulty: any,
    meanDifficulty: any,
    minDifficulty: any,
    maxDifficulty: any,
    avgBadReactionTime: any,
    avgGoodReactionTime: any,
    trials: any
}
interface nrContent {
  id: string,
  patient: any,
  event: any,
  createdAt: any,
  type: any,
  content: innerContent | JSON
}

@Component({
  selector: 'page-neuronation',
  templateUrl: 'neuronation.page.html',
  styleUrls: ['./neuronation.page.scss']
})
export class NeuronationPage implements OnInit {

    private event?: Event;
    private neuroResultSubscription?: Subscription;
    public neuroResults: any | nrContent[] = [];
    public patient!: Patient;
    public lang?: string = 'de';
    public isReady = false;
    public exercises;
    public training;
    public exerciseId = {
      101: "Memobox",
      102: "FormFever",
      103: "Reflector",
      104: "AlphabetSoup",
      105: "Rotator",
      106: "Solitaria",
      107: "ChainReaction",
      108: "Restorer",
      109: "MathBlitz",
      110: "FocusMaster",
      111: "Symbolism",
      112: "Formula",
      113: "FormFusion",
      114: "TrailTracker",
      115: "LogiLadder",
      116: "MathRobatics",
      117: "Memoflow",
      118: "FlashGlance",
      119: "Shuffler",
      120: "ClockWise",
      121: "PathFinder",
      122: "PathFinderReverse",
      123: "ColorCraze",
      124: "Turnabout",
      125: "QuantumLeap",
      126: "SpeedOfLight",
      127: "Wordsmith",
      128: "WordAcrobat",
      129: "VerbalVoyager",
      130: "PolaroidPicture",
      131: "MemoPair",
      132: "QuickSwitch",
      135: "QuickAlert",
      136: "Parita",
      137: "IQube",
      201: "Memobox",
      202: "FormFever",
      203: "Reflector",
      204: "AlphabetSoup",
      205: "Rotator",
      206: "Solitaria",
      207: "ChainReaction",
      208: "Restorer",
      209: "MathBlitz",
      210: "MathBlitz",
      211: "Symbolism",
      212: "Formula",
      213: "FormFusion",
      214: "TrailTracker",
      215: "LogiLadder",
      216: "MathRobatics",
      217: "Memoflow",
      218: "FlashGlance",
      219: "Shuffler",
      220: "ClockWise",
      221: "PathFinder",
      222: "PathFinderReverse",
      223: "ColorCraze",
      224: "Turnabout",
      225: "QuantumLeap",
      226: "SpeedOfLight",
      227: "Wordsmith",
      228: "WordAcrobat",
      229: "VerbalVoyager",
      230: "PolaroidPicture",
      231: "MemoPair",
      232: "QuickSwitch",
      235: "QuickAlert",
      236: "Parita",
      237: "IQube",
      301: "Memobox",
      302: "FormFever",
      303: "Reflector",
      304: "AlphabetSoup",
      305: "Rotator",
      306: "Solitaria",
      307: "ChainReaction",
      308: "Restorer",
      309: "MathBlitz",
      310: "FocusMaster",
      311: "Symbolism",
      312: "Formula",
      313: "FormFusion",
      314: "TrailTracker",
      315: "LogiLadder",
      316: "MathRobatics",
      317: "Memoflow",
      318: "FlashGlance",
      319: "Shuffler",
      320: "ClockWise",
      321: "PathFinder",
      322: "PathFinderReverse",
      323: "ColorCraze",
      324: "Turnabout",
      325: "QuantumLeap",
      326: "SpeedOfLight",
      327: "Wordsmith",
      328: "WordAcrobat",
      329: "VerbalVoyager",
      330: "PolaroidPicture",
      331: "MemoPair",
      332: "QuickSwitch",
      335: "QuickAlert",
      336: "Parita",
      337: "IQube",
      1001: "Memobox",
      1002: "FormFever",
      1005: "Rotator",
      1006: "Solitaria",
      1008: "Restorer",
      1011: "Symbolism",
      1012: "Formula",
      1013: "FormFusion",
      1021: "PathFinder",
      1022: "PathFinderReverse",
      1023: "ColorCraze",
      1025: "QuantumLeap",
      1029: "VerbalVoyager",
      1111: "Symbolism",
      1132: "QuickSwitch",
      1133: "MissingLink",
      1134: "WordSymbolMemory",
      1234: "WordSymbolMemory"
    };

    public domainId = {
      1: "Speed",
      2: "Reasoning",
      3: "Attention",
      4: "Memory",
    };

    constructor(
      private readonly apiNeuronation: ApiNeuronation,
      private readonly neuronationProvider: NeuronationProvider,
      private readonly loading: LoadingProvider,
      private readonly location: Location,
      private readonly router: Router,
      private readonly route: ActivatedRoute,
      private readonly user: User,
    ) {
    }

    ngOnInit() {
      this.route.queryParams.subscribe(params => {
        const state = this.router.getCurrentNavigation()?.extras.state;
        if (state) {
          this.lang = this.user?.getLang();
          this.exercises = exercises;
          this.event = state.event;
          this.neuronationProvider.fetchNeuroResultsForEvent(state.event.id);
          this.neuroResultSubscription = this.getSubscriptionForNeuroResults();
          void this.loadNeuroData();
        }
      });
    }

  async loadNeuroData() {

    let tLang = 'de';
    if (this.lang) {
      tLang = this.lang;
    }

    zip(this.apiNeuronation.fetchExerciseTitles(tLang), this.apiNeuronation.fetchTrainingData(tLang))
      .subscribe((res) => {
        if (isArray(res)) {
          this.training = res[1];
          this.exercises = res[0];
        }
        this.isReady = true;
      })
  }

    ionViewWillEnter(): void {
      if (this.lang !== this.user.getLang()) {
        this.lang = this.user.getLang();
        void this.loadNeuroData();
      }
      this.loading.presentLoading('...', 1000).then(r => this.loading.dismissLoading());
    }

    ionViewWillLeave(): void {
      this.neuroResultSubscription?.unsubscribe();
    }

    ionViewDidLeave(): void {
      this.neuronationProvider.clear();
    }

    public goBackToTherapyList() {
      this.location.back();
    }

    private getSubscriptionForNeuroResults(): Subscription {
        return this.neuronationProvider.getNeuroResultsForEvent()
          .subscribe((neuroResults: NeuroResult[]) => {
            this.neuroResults = neuroResults;
          })
    }
}
