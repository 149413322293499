import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatCalendarCellClassFunction, MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { CalendarHeaderComponent } from './calendar-header/calendar-header';

@Component({
  selector: 'datepicker',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['datepicker.component.scss']
})
export class DatepickerComponent {
  calendarHeaderComponent = CalendarHeaderComponent;
  private readonly format = 'YYYY-MM-DD';
  private readonly default_min = moment([new Date().getFullYear() - 100, 0, 1]);
  private readonly default_max = moment([new Date().getFullYear() + 3, 0, 1]);
  private readonly default_date = moment();

  //Type 'string | null' is not assignable to type 'Moment | null'. https://material.angular.io/components/datepicker/api#MatDatepickerInput
  _min: moment.Moment = this.default_min
  _max: moment.Moment = this.default_max
  _date: moment.Moment = this.default_date;

  @Input() set min(min: string | null) {
    if(min) this._min = moment(min, this.format);
  }

  @Input() set max(max: string | null) {
    if(max) this._max = moment(max, this.format);
  }

  @Input() set date(date: string | null) {
    if(date) this._date = moment(date, this.format);
  }

  @Input() disabled = false;
  @Input() iconClass = 'evo-calendar';
  @Output() dateChange: EventEmitter<string> = new EventEmitter<string>();

  dateClass: MatCalendarCellClassFunction<moment.Moment> = (cellDate, view) => {
    if (cellDate.day() === 0) {
      return 'holiday';
    }
    return '';
  }

  changeDate(change: MatDatepickerInputEvent<moment.Moment>) {
    let date = change.value;
    if(!date) {
      date = moment();
    }
    this.dateChange.emit(date.format('YYYY-MM-DD'));
  }
}
