import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Storage } from '../storage/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorProvider implements HttpInterceptor {

  constructor(private readonly storage: Storage) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = this.storage.id_token;

    if (req.headers.get('skip')) {
      let headers = new HttpHeaders();
      headers.delete('Authorization');
      headers = headers.append(
        'Authorization',
        'Basic dGhlcmFwaWVfc3lzdGVtOms2cnR3bG05'
      );
      headers.delete('skip');
      const cloned = req.clone({
        headers,
      });

      return next.handle(cloned);
    } else {
      if (idToken) {
        const cloned = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + idToken),
        });

        return next.handle(cloned);
      } else {
        return next.handle(req);
      }
    }
  }

}
