import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Storage {

  private readonly TOKEN_KEY = 'id_token';
  private readonly EXPIRE_KEY = 'expires_at';
  private readonly API_PATH = 'api_path';
  private readonly SOCKET_PATH = 'socket_path';

  constructor() { }

    set(expires: number, token: string) {
      const expiresAt = Math.floor(Date.now() / 1000) + expires;
      localStorage.setItem(this.TOKEN_KEY, token);
      localStorage.setItem(this.EXPIRE_KEY, JSON.stringify(expiresAt));
    }

    setApiPath(path) {
      localStorage.setItem(this.API_PATH, path);
    }

    setSocketPath(path) {
      localStorage.setItem(this.SOCKET_PATH, path);
    }

    clear() {
      localStorage.clear();
    }

    isExpired() {
      const expires_at = localStorage.getItem(this.EXPIRE_KEY);
      if(!expires_at) {return true;}
      const validDate = Math.floor(Date.now() / 1000) < parseInt(JSON.parse(expires_at), 10);
      return !validDate;
    }

    get id_token() {
      return localStorage.getItem(this.TOKEN_KEY);
    }

}
