import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Api } from '../services/api/api';
import { Item } from '../models';
import { DisplayMode } from '../models/display-mode';
import {User} from "../services/user/user";

/**
 * The CatalogueItemPreviewPage shows the information of a selected catalogue item.
 * The user can confirm or cancel the selection.
 */
@Component({
  selector: 'page-catalogue-item-preview',
  templateUrl: 'catalogue-item-preview.page.html',
  styleUrls: ['catalogue-item-preview.page.scss']
})
export class CatalogueItemPreviewPage implements OnInit {

  /** The selected item that is shown in this preview. */
  @Input()
  public item!: Item;
  /** Display mode is "tab" or "modal" depending on whether it was called from the catalogues tab or modal. */
  @Input()
  public displayMode: DisplayMode = DisplayMode.Modal;
  /** Url used to get the images, video and audio. */
  public cdnUrl: string;
  /** Nested array of questions with answers for surveys. */
  public questionsWithAnswers: Array<Array<string>> = [];

  constructor(
    private readonly api: Api,
    private readonly modalCtrl: ModalController,
    private readonly user: User
  ) {
    this.cdnUrl = this.api.cdnUrl;
  }

  ngOnInit() {
    if (this.item.treatment.name === 'Survey' && this.item.text) {
      const allQuestions = this.item.text.split('#');
      for (let i = 0; i < allQuestions.length; i++) {
        this.questionsWithAnswers.push(allQuestions[i].split('|'));
      }
    }
  }

  /**
   * Close the modal without selecting the shown catalogue item.
   */
  public cancel(): void {
    this.modalCtrl.dismiss();
  }

  /**
   * Close the modal with selecting the shown catalogue item.
   */
  public submit(): void {
    this.modalCtrl.dismiss(this.item);
  }

  get canSubmit() {
    if ( this.item?.treatment?.name === 'NeuroNation' && this.user.user?.account.hasNeuroNation === false) {
      return false;
    }
    return this.displayMode === DisplayMode.Modal;
  }

}
