import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

/**
 * The ConfirmationDialogPage shows a text warning and asks the user to confirm an action.
 * It is used to confirm the deletion of exercises.
 */

@Component({
  selector: 'page-confirmation-dialog',
  templateUrl: 'confirmation-dialog.page.html',
  styleUrls: ['./confirmation-dialog.page.scss'],
})
export class ConfirmationDialogPage {

  /** The text being shown. */
  @Input()
  public text!: string;

  constructor(
    private readonly modalCtrl: ModalController
  ) {
  }

  /**
   * Close the modal with a rejection.
   */
  public cancel(): void {
    void this.modalCtrl.dismiss(false);
  }

  /**
   * Close the modal with a confirmation.
   */
  public submit(): void {
    void this.modalCtrl.dismiss(true);
  }

}

