import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TherapyPackage } from '../models';
import { DisplayMode } from '../models/display-mode';

/**
 * The PackageItemPreviewPage shows the information of a selected package item.
 * The user can confirm or cancel the selection.
 */
@Component({
  selector: 'page-package-item-preview',
  templateUrl: 'package-item-preview.page.html',
  styleUrls: ['./package-item-preview.page.scss']
})
export class PackageItemPreviewPage {

  /** The selected item that is shown in this preview. */
  @Input()
  public therapyPackage!: TherapyPackage;
  /** Display mode is "tab" or "modal" depending on whether it was called from the packages tab or modal. */
  @Input()
  public displayMode = DisplayMode.Modal;

  constructor(
    private readonly modalCtrl: ModalController
  ) {
  }
  /**
   * Close the modal without selecting the shown package item.
   */
  public cancel(): void {
    this.modalCtrl.dismiss();
  }

  /**
   * Close the modal with selecting the shown package item.
   */
  public submit(): void {
    this.modalCtrl.dismiss(this.therapyPackage);
  }

  get modalDisplay() {
    return this.displayMode === DisplayMode.Modal;
  }

}
