import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationProvider } from '../notification/notification';
import { User } from '../user/user';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly user: User,
    private readonly notification: NotificationProvider,
    private readonly translate: TranslateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.user.isLoggedIn()) {
      this.notification.presentToast(this.translate.instant('General.Error.SessionExpired'));
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }
}
