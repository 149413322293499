import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

/**
 * The EventInformationModalPage shows the information
 * defined by the medical for the event-series.
 */
@Component({
  selector: 'page-event-information-modal',
  templateUrl: 'event-information-modal.page.html',
  styleUrls: ['./event-information-modal.page.scss']
})
export class EventInformationModalPage {

  /** The information being shown to the  */
  @Input()
  public infos: string = '';

  constructor(
    private readonly modalCtrl: ModalController
  ) {
  }

  /**
   * Close the modal.
   */
  continueClick() {
    this.modalCtrl.dismiss();
  }

}
