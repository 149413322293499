import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CatalogueItemPreviewPageRoutingModule } from './catalogue-item-preview-routing.module';
import { CatalogueItemPreviewPage } from './catalogue-item-preview.page';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  imports: [
    IonicModule,
    TranslateModule,
    CatalogueItemPreviewPageRoutingModule,
    CommonModule,
    NgScrollbarModule
  ],
  declarations: [CatalogueItemPreviewPage]
})
export class CatalogueItemPreviewPageModule {}
