import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { EventCalendarComponent } from './event-calendar/event-calendar';
import { EventCalendarHeaderComponent } from './event-calendar-header/event-calendar-header';
import { PipesModule } from '../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LanguagePickerComponent } from './language-picker/language-picker.component';
import { FormsModule } from '@angular/forms';
import { CalendarHeaderComponent } from './datepicker/calendar-header/calendar-header';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
	declarations: [
		CalendarHeaderComponent,
		DatepickerComponent,
		EventCalendarComponent,
		EventCalendarHeaderComponent,
		LanguagePickerComponent
	],
	imports: [
		IonicModule,
		PipesModule,
		TranslateModule,
		CommonModule,
		MatDatepickerModule,
		FormsModule,
		NgScrollbarModule
	],
	exports: [
		DatepickerComponent,
		EventCalendarComponent,
		EventCalendarHeaderComponent,
		LanguagePickerComponent,
		MatDatepickerModule
	]
})
export class ComponentsModule { }
