import {Component, Input} from "@angular/core";
import { ApiNeuronation } from "../services";
import { Patient } from "../models";
import { NavigationExtras, Router} from "@angular/router";
import { zip} from "rxjs";
import { isArray } from "lodash";
import { User } from "../services/user/user";


@Component({
  selector: 'page-evo-neuro',
  templateUrl: 'evo-neuro.page.html',
  styleUrls: ['./evo-neuro.page.scss']
})
export class EvoNeuroPage {

  @Input()
  public lang?: string;
  public patient!: Patient | any;
  private userId;
  private sessionToken;
  public exercises;
  public training;
  public isReady = false;
  public showNotStarted = false;

  constructor( private readonly apiNeuronation: ApiNeuronation,
               private readonly router: Router,
               private readonly user: User,
              ) {
  }

  ngOnInit(): void {
    const state = this.router.getCurrentNavigation()?.extras.state;
    if (state) {
      this.patient = state.patient;
      this.lang = this.user?.getLang();
      if (this.patient) {
        if (!this.patient.neuroNationID) {
          this.showNotStarted = true;
        } else {
          void this.loadNeuroData();
          this.getSessionToken();
        }
      }
    }
  }

  ionViewWillEnter() {
    if (this.lang !== this.user.getLang()) {
      this.lang = this.user.getLang();
      void this.loadNeuroData();
    }
  }

  getSessionToken() {
    if (this.patient) {
      this.apiNeuronation.getSessionToken(this.patient.id).subscribe((res) => {
        this.userId = res.userId;
        this.sessionToken = res.sessionToken;
      })
    }
  }

  async loadNeuroData() {

    let tLang = 'de';
    if (this.lang) {
      tLang = this.lang;
    }

    zip(this.apiNeuronation.fetchExerciseTitles(tLang), this.apiNeuronation.fetchTrainingData(tLang))
      .subscribe((res) => {
          if (isArray(res)) {
             this.exercises = res[0];
             this.training = res[1];
          }
        this.isReady = true;
      })
  }

  async showNeuronationSettings() {
    let navParams: NavigationExtras;

      navParams = {
        state: {
          patient: this.patient,
          userId: this.userId,
          sessionToken: this.sessionToken,
          training: this.training,
          exercises: this.exercises
        }
      }
    void this.router.navigate(['/tabs/evo-neuro/neuronation-settings'], navParams);
  }

  async showNeuronationResults() {
    let navParams: NavigationExtras;

    navParams = {
      state: {
        patient: this.patient,
        userId: this.userId,
        sessionToken: this.sessionToken,
        training: this.training,
        exercises: this.exercises
      }
    }

    void this.router.navigate(['/tabs/evo-neuro/neuronation-results'], navParams);
  }


  goBack() {
    this.exercises = [];
    this.training = [];
    const navParams: NavigationExtras = {
      replaceUrl: true,
      state: {
        patient: this.patient
      }
    };
    void this.router.navigate(['/tabs/patients/patient-detail/'], navParams);
  }



}
