import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { IonicRouteStrategy, IonicModule } from '@ionic/angular';
import { AppUpdate } from '@ionic-native/app-update/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';
import { Api } from './services/api/api';
import { User } from './services/user/user';
import {
    StreamProvider,
    AuthInterceptorProvider,
    NotificationProvider,
    LoadingProvider,
    CatalogueProvider,
    EventsProvider,
    PatientsProvider,
    TherapyUnitProvider,
    GroupTherapyProvider
} from './services';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TherapyUnitDetailPageModule } from './therapy-unit-detail/therapy-unit-detail.module';
import { CatalogueItemPreviewPageModule } from './catalogue-item-preview/catalogue-item-preview.module';
import { VideocallPageModule } from './videocall/videocall.module';
import { EvoNeuroPageModule } from './evo-neuro/evo-neuro.module';
import { NeuronationPageModule } from './neuronation/neuronation.module';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppInitializerFactory } from './factories/app.initializer.factory';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import 'moment/locale/de';
import 'moment/locale/ja';
import { ApiErrorInterceptor } from './services/api-interceptor/api.interceptor';

// The translate loader needs to know where to load i18n files
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        ComponentsModule,
        IonicModule.forRoot({
            scrollAssist: false,
            swipeBackEnabled: false
        }),
        IonicStorageModule.forRoot(),
        PipesModule,
        TherapyUnitDetailPageModule,
        CatalogueItemPreviewPageModule,
        VideocallPageModule,
        NeuronationPageModule,
        EvoNeuroPageModule,
        MatNativeDateModule
    ],
    bootstrap: [AppComponent],
    providers: [
        Api,
        User,
        PipesModule,
        // Keep this to enable Ionic's runtime error handling during development
        /*{
          provide: ErrorHandler
        },*/
        AppUpdate,
        StreamProvider,
        Storage,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorProvider,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiErrorInterceptor,
            multi: true
        },
        NotificationProvider,
        LoadingProvider,
        TherapyUnitProvider,
        CatalogueProvider,
        EventsProvider,
        PatientsProvider,
        GroupTherapyProvider,
        DatePipe,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        {
            provide: APP_INITIALIZER,
            useFactory: AppInitializerFactory,
            deps: [TranslateService],
            multi: true
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ]
})
export class AppModule { }

