import { Injectable } from '@angular/core';
import { Event, EventSeries, EventSeriesSnapshot } from "../../models";
import { Api } from "../api/api";

@Injectable({
    providedIn: 'root'
})
export class Duration {

  private readonly cdnUrl: string;

  constructor(
      private api: Api
  ) {
    this.cdnUrl = this.api.cdnUrl;
  }

  async getDuration(event: Event) {

    let duration = 0;

    if (event.eventSerie?.item?.treatment.name === 'Relax' ||
      event.eventSerie?.item?.treatment.name === 'EvoRelax' ||
      event.eventSerie?.item?.treatment.name === 'Feed') {

      const audioId = event.eventSerie.item.audioId;
      const videoId = event.eventSerie.item.videoId;
      let aTime = 0;
      let vTime = 0;
      if (audioId) {
        aTime = await this.getAudioDuration(audioId);
      }
      if (videoId) {
        vTime = await this.getVideoDuration(videoId);
      }
      duration += (aTime >= vTime) ? aTime : vTime;
    } else if (event?.eventSerie?.item?.treatment.name === 'Grouptherapy') {
          if (event?.eventSerieSnapshot) {
            duration += event?.eventSerieSnapshot?.configDuration!;
          } else if (event?.eventSerie) {
            duration += event?.eventSerie?.configDuration!;
          }
    } else {
      if (event?.eventSerieSnapshot) {
        duration += this.getExerciseDuration(event?.eventSerieSnapshot);
      } else if (event?.eventSerie) {
        duration += this.getExerciseDuration(event?.eventSerie);
      }
    }
    return duration;
  }

  /**
   * Get duration of given audio file
   *
   * @param audioId path to audio file
   */
  public async getAudioDuration(audioId: string): Promise<any> {

    return new Promise((resolve, reject) => {
      const audio = new Audio(this.cdnUrl + audioId);
      audio.preload = 'metadata';
      audio.onloadedmetadata = () => {
        audio && audio.duration ? resolve(audio.duration) : reject(undefined);
      };
      audio.onerror = () => {
        reject('Error getting audio duration');
      };
    });
  }

  /**
   * Get duration of the video file of an exercise
   *
   * @param videoId   Path to video file
   * @returns         Promise for the duration of the video file
   */
  private async getVideoDuration(videoId: string): Promise<any> {

    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.setAttribute('src', this.cdnUrl + '/' + videoId);
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        (video && video.duration) ? resolve(video.duration) : reject('Error getting the video duration!');
      };
      video.onerror = () => {
        reject('Error getting the video duration!');
      };
    });
  }

  /**
   * Calculates the total duration of the exercise (without the preparation time).
   *
   * @param eventSerie    The event series of the exercise
   * @returns             The duration of the exercise
   */
  public getExerciseDuration(eventSerie: EventSeries | EventSeriesSnapshot | null | undefined): number {
    if(!eventSerie) return 0;

    if (!eventSerie.configCountSets || !eventSerie.configCountReps || !eventSerie.configDuration
      || !eventSerie.configCountSets || !eventSerie.configTimeBetweenSets) {
      return 0;
    }
    const perDay = eventSerie?.perDay || eventSerie?.perDay || 0;
    const setDuration = eventSerie.configCountSets * eventSerie.configCountReps * eventSerie.configDuration;
    const breakDuration = (eventSerie.configCountSets - 1) * eventSerie.configTimeBetweenSets;

    return (setDuration + breakDuration) * perDay;
  }
}
