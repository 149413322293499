import { Theme } from "src/app/models/theme.model";

export const ENV = {
  theme: {
    baseUrl: "/assets/themes/",
    default: Theme.Dark,
    enableSwitch: true,
  },
  availableLanguages: ['de', 'en', 'ja'],
  production: true,
  apiUrl:"https://cmp.tor.evocare.org/api/v1",
  prmUrl:"https://prm.evocare.org/prm",
  baseUrl: "https://medical.tor.evocare.org",
  cdnUrl: "https://cmp.evocare.de/cdn/",
  socketUrl: "https://cmp.tor.evocare.org",
  uploadUrl: "https://cmp.tor.evocare.org/uploads/",
  useSso: false,
  ssoUrl: "https://prm.evocare.org/prm/api/v1",
  ssoUser: "api_medical",
  ssoPassword: "api_medical_pass",
  icdServer: "https://cdn.evocare.de/icd"
};
