
import { map, share } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Api } from '../api/api';
import { Storage } from '../storage/storage';
import { Router } from '@angular/router';
import { Login } from 'src/app/models/login.model';
import { Medical } from 'src/app/models';
import { MessageProvider } from '../message/message';

@Injectable({
  providedIn: 'root'
})
export class User {

  /** The current user being logged in. */
  private _user: Medical | null = null;

  constructor(
    private readonly api: Api,
    private readonly storage: Storage,
    private readonly router: Router,
    private readonly messageProvider: MessageProvider
  ) { }

  public login(accountInfo: any): Observable<Login> {
    const loginData = new FormData();
    loginData.append('username', accountInfo.username);
    loginData.append('password', accountInfo.password);
    loginData.append('subject', 'medical');
    return this.api.post('Login', loginData).pipe(share());
  }

  async defineApiPath(username: string) {
    return await this.api.ssoPost('getApiSlugForCmp', {username: username, role: 'medical'})
  }

  resetUrl() {
    this.api.resetUrl();
  }

  //todo: type of Observable to be fixed:
  public changePassword(oldPassword: string, newpassword: string) {
    return this.api.post<{ id: string, apipass: string }>('medical/updatePassword', { medicalid: this.user?.id, oldpassword: oldPassword, newpassword });
  }

  public changeTheme() {
    return this.api.post<{ id: string }>('medical/setUseDarkTheme', { medicalId: this.user?.id });
  }

  public async changeLanguage(medicalId: string, lang: string) {
    return this.api.post('medical/changeLang', { medicalId: medicalId, lang: lang }).pipe(share());
  }

  public async setNotesRead(patientId: string) {
    return this.api.post('patient/clearNewNotice', { patientId: patientId }).pipe(share());
  }
  /**
   * Log the user out, which forgets the session
   */
  public logout(): void {
    this._user = null;
    this.storage.clear();
    this.messageProvider.disconnect();
    this.router.navigate(['/login'])
      .then(() => {
      location.reload();
    });
  }

  public isLoggedIn() {
    return this._user !== null && !this.isExpired();
  }

  public isExpired() {
    return this.storage.isExpired();
  }

  /**
   * Process a login/signup response to store user data
   */
  public set user(user) {
    if (!user) {
      this._user = null;
    } else {
      this._user = user;
    }
  }

  public getId() {
    if (this._user) {
    return this._user.id;
    }
  }

  public get user() {
    return this._user;
  }

  public getLang() {
    return this._user?.lang;
  }

  public setLang(lang: string) {
    if (this._user) {
      this._user.lang = lang;
    }
  }

}
