import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { NeuronationPage } from './neuronation.page';
import { PipesModule } from '../pipes/pipes.module';
import { CommonModule} from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NeuronationPageRoutingModule } from "./neuronation-routing.module";

@NgModule({
  imports: [
    TranslateModule,
    PipesModule,
    IonicModule,
    CommonModule,
    NeuronationPageRoutingModule,
    NgScrollbarModule
  ],
  declarations: [NeuronationPage]
})
export class NeuronationPageModule {}
