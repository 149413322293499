import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import * as _ from 'lodash';

import {Api} from '../api/api';
import {User} from '../user/user';
import {CalendarItem, Event, EventSeries} from '../../models';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class EventsProvider {

    /** The event source of the calendar for the current patient as a BehaviorSubject for manually assigning new values. */
    private calEventSource$$: BehaviorSubject<CalendarItem[]> = new BehaviorSubject<CalendarItem[] >([]);
    /** The event source of the calendar for the current patient as an Observable for subscriptions in components. */
    private calEventSource$: Observable<CalendarItem[]> = this.calEventSource$$.asObservable();

    constructor(
        public api: Api,
        private user: User,
    ) { }
    /**
     * Load the events for the calendar of a patient.
     *
     * @param patientId     The id of the patient
     */
    public loadEventSourceByPatientId(patientId: string): void {
        this.emptyEventSource();
        this.api.get<Event[]>('event/getAllEventsByPatientId', { patientid: patientId }).pipe(
            map((res) => res.map((ev: Event) => this.createCalendarItemForEvent(ev)))).subscribe(
                (res) => this.setEventSource(res),
                (err) => console.error(err)
            );
    }

    /**
     * Get the stored event source for the calendar.
     *
     * @returns     The stored event source
     */
    public getEventSource(): Observable<CalendarItem[]> {
        return this.calEventSource$;
    }

    /**
     * Empty the stored event source for the calendar.
     */
    public emptyEventSource(): void {
        this.calEventSource$$.next([]);
    }

    /**
     * Send request to create a new event series.
     *
     * @param eventSeries   The event series to create
     * @returns             Http-Response is the list of events in the new event series
     */
    //todo: fix eventSeries type
    public addEventSeries(eventSeries: EventSeries): Observable<Event[]> {
      if(eventSeries.therapyPackage) {
        //therapyPackage have to be as item CreateEventSerie.controller:24
        eventSeries.item = (eventSeries.therapyPackage as any);
      }
      return this.api.post('event/createEventStream', { createdby: this.user.user!.id, eventserie: eventSeries }).pipe(
          tap((res: Event[]) => {
                if (res && res.length) {
                    // Add the new events to the event source of the calendar
                    let curCalEventSource = this.calEventSource$$.getValue();
                    curCalEventSource =  (curCalEventSource || []).concat(res.map(ev => this.createCalendarItemForEvent(ev)));
                    this.setEventSource(curCalEventSource);
                }
                else {
                    console.error('No event data retrieved', res);
                }
            })
        );
    }

    /**
     * Send request to update an event series.
     *
     * @param eventSeries   The event series to update
     * @returns             Http-Response is the list of events in the updated event series
     */
    public updateEventSeries(eventSeries: EventSeries): Observable<Event[]> {
        return this.api.post('event/updateEventStream', { modifiedby: this.user.user!.id, eventserie: eventSeries }).pipe(
          //todo: type of res to be fixed:
            tap((res: any) => {
              if (res && res.length) {
                let curCalEventSource = this.calEventSource$$.getValue();
                curCalEventSource = (curCalEventSource || []).filter((item: CalendarItem) => item.event.eventSerie?.id !== res[0].eventSerie.id);
                curCalEventSource = curCalEventSource.concat(res.map((ev: Event) => this.createCalendarItemForEvent(ev)));
                this.setEventSource(curCalEventSource);
              }
            })
        );
    }

    /**
     * Send request to delete a single event.
     *
     * @param event   The event to delete
     * @returns       Http-Response if deletion was successfull
     */
    public deleteEvent(event: Event): Observable<any> {
        return this.api.post('event/deleteEventById', { id: event.id, medicalid: this.user.user!.id }).pipe(
            tap(() => {
                // Remove the event from the event source of the calendar
                const curCalEventSource = this.calEventSource$$.getValue();
                _.remove(curCalEventSource, (citem: CalendarItem) => citem.event.id === event.id);
                this.setEventSource(curCalEventSource);
            })
        );
    }

    /**
     * Send request to delete an event series.
     *
     * @returns       Http-Response if deletion was successfull
     * @param eventSeries
     */
    public deleteEventSeries(eventSeries: EventSeries): Observable<any> {
        const today = moment().format('YYYY-MM-DD');
        return this.api.post('event/deleteEventStreamById', { id: eventSeries.id, medicalid: this.user.user!.id }).pipe(
            tap(() => {
                // Remove all upcoming events of the event series from the event source of the calendar
                const curCalEventSource = this.calEventSource$$.getValue();
                _.remove(curCalEventSource, (citem: CalendarItem) => (
                  citem.event.eventSerie?.id === eventSeries.id && citem.event.executed === 0 && citem.event.date >= today));
                this.setEventSource(curCalEventSource);
            })
        );
    }

    /**
     * Get the events of an event series.
     *
     * @param eventSeries   The event series
     * @returns             The corresponding events
     */
    public getEventsOfEventSeries(eventSeries: EventSeries): Event[] {
        const curCalEventSource = this.calEventSource$$.getValue();
        return curCalEventSource.filter((calItem) => (calItem.event.eventSerie?.id === eventSeries.id)).map((calItem) => calItem.event);
    }

  /**
   * Create a calendar item from an event.
   *
   * @param event     The event
   * @returns         The calendar item containing the event
   */
  private createCalendarItemForEvent(event: Event): CalendarItem {
    return {
      allDay: false,
      title: event.eventSerie?.name || '',
      startTime: new Date(event.date),
      endTime: new Date(event.date),
      event,
      audioDuration: 0,
      showGroupTherapy: false
    };
  }

  /**
   * Set the stored event source for the calendar.
   *
   * @param eventSource   The event source for the calendar
   */
  private setEventSource(eventSource: CalendarItem[]): void {
    this.calEventSource$$.next(eventSource);
  }
}
