import { Injectable } from '@angular/core';
import { Api } from '../api/api';

@Injectable({
  providedIn: 'root'
})
export class ApiNeuronation {

  constructor(
    private api: Api
  ) { }

  public getSessionToken(patientId: string) {
    return this.api.post('neuroNation/loginForMedical', {patientId: patientId});
  }

  public fetchNeuroCyclesForPatient(patientId: string, userId: string, sessionToken: string) {
    return this.api.post('neuroNation/getCyclesData', {patientId: patientId, userId: userId, sessionToken: sessionToken})
  }

  public patchTrainingCycle(patientId: string, userId: string, sessionToken: string, data: object) {
    return this.api.post('neuroNation/patchTrainingCycle', {
          patientId: patientId,
          userId: userId,
          sessionToken: sessionToken,
          data: data
    });
  }

  public fetchNeuroResultsForPatient(patientId: string, userId: string, sessionToken: string) {
    return this.api.post('neuroNation/getResults', {patientId: patientId, userId: userId, sessionToken: sessionToken})
  }

  public fetchTrainingData(lang: string): any {
    return this.api.get('neuroNation/getTrainingData?lang=' + lang);
  }

  public fetchExerciseTitles(lang: string): any {
      return this.api.get('neuroNation/getExerciseTitles?lang=' + lang);
  }


}
