import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PatientStream } from 'src/app/models/api/patient-stream.model';

import { Api } from '../api/api';

@Injectable({
  providedIn: 'root'
})
export class StreamProvider {

  private readonly authCredentials = "Basic dGhlcmFwaWVfc3lzdGVtOms2cnR3bG05";

  constructor(
    public http: HttpClient,
    private api: Api
  ) { }
  
  /**
   *
   * @param id  The patient id
   */
  public getPatientStream(id: string): Observable<PatientStream> {
    let url = this.api.prmUrl + "/api/v1/Patient/" + id + "/stream?filter=&maxSize=20&offset=0&sortBy=number&asc=false"
    let headers =  new HttpHeaders();
    headers = headers.set("Authorization", this.authCredentials).set("skip", "true");
    let reqOpts = {
      headers,
      withCredentials: false  // WITH CREDENTIALS? => not for PRM / CORS
    };
    return this.http.get<PatientStream>(url, reqOpts);
  }

  public sendNotice(note: any): Observable<any> {
    let url = this.api.prmUrl + "/api/v1/Note";
    let headers =  new HttpHeaders();
    headers = headers.set("Authorization", this.authCredentials).set("skip", "true");
   // headers = headers.append("Content-Type", "application/json");
    let reqOpts = {
      headers,
      withCredentials: false
    };
    return this.http.post(url, note, reqOpts);
  }
}
