import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seconds',
})
export class SecondsPipe implements PipeTransform {

  transform(value: number): string {
    let seconds = value/1000;
    return `${seconds}`
  }
}
