import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Api} from '../services/api/api';
import {EventsProvider, NotificationProvider, PackageProvider} from '../services';
import {Event, EventSeries, Item, Patient, TherapyPackage, VideoTimes} from '../models';
import {CataloguePage} from '../catalogue/catalogue.page';
import {TherapyPackageCatalogue} from '../package-catalogue/package-catalogue.page';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {DisplayMode} from '../models/display-mode';
import {EditMode} from '../models/edit-mode';
import {ConfirmationDialogPage} from '../confirmation-dialog/confirmation-dialog.page';
import { ENV } from '../../environments/environment';

/**
 * In the EventCreatePage the medical can choose an exercise
 * and set the configuration and dates for a new event series.
 */
@Component({
  selector: 'page-event-create',
  templateUrl: 'event-create.page.html',
  styleUrls: ['./event-create.page.scss'],
  providers: [PackageProvider]
})
export class EventCreatePage {
  @Input()
  patient!: Patient;
  @Input()
  selectedDay: moment.Moment = moment();
  @Input()
  feedbackDefault = false;
  @Input()
  public eventSeries!: EventSeries;
  /** The events of the event series that is being edited in this page. */
  @Input()
  public events!: Event[];
  @Input()
  public editMode!: EditMode;

  /** Flag to indicate that the event series has already begun. */
  public hasStarted = false;
  /** Flag to indicate that the event series has more events to be done. */
  public hasEnded = false;
  /** Flag to indicate that it's extending. */
  public isExtend: boolean = false;
  /** Flag to indicate that loading the audio of a relax treatment failed. */
  private hasAudioNotFoundError = false;

  /** The date of today in ISO-format. */
  public today = moment().format('YYYY-MM-DD');
  /** Flag to indicate that a catalogue item has been selected. */
  public isItemSelected = false;

  /** The error message that is shown at the bottom of the page, if any input is invalid. */
  public errorMessage = '';
  /** The timeframes of the video recordings for the event series. */
  public videoMoments: VideoTimes[] = [];
  /* Default value has feedback */
  //private feedbackDefault = false;
  /* Does the clinic have therapy packages */
  public hasPackages = false;
  /* List of therapy packages if there are any */
  public therapyPackages: Array<TherapyPackage> = [];
  public pageTitle!: string;
  public buttonTitle!: string;
  public isProduction: boolean = true;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly eventsProvider: EventsProvider,
    private readonly notification: NotificationProvider,
    private readonly api: Api,
    private readonly packageProvider: PackageProvider,
    private readonly translate: TranslateService
  ) {
    // Set default parameters for video start/end
    if(this.editMode === EditMode.create) {
      this.addVideoParameters();
    }
    if (!ENV.production) {
      this.isProduction = false;
    }
  }

  ionViewWillEnter(): void {
    switch (this.editMode) {
      case EditMode.create:
        this.pageTitle = 'Event.Title';
        this.buttonTitle = 'Event.Add';
        this.packageProvider.getData().subscribe((packages) => {
          if (!packages) {
            return;
          }
          this.therapyPackages = packages;
          if (this.therapyPackages.length > 0) {
            this.hasPackages = true;
          }
        });
        const defaultDate = this.selectedDay.format('YYYY-MM-DD');
        this.eventSeries = {
          id: '',
          name: '',
          item: null,
          patient: this.patient,
          frequenceDay: 1,
          perDay: 1,
          perSession: 1,
          hasFeedback: this.feedbackDefault,
          prescribeOnce: false,
          infos: '',
          startdate: defaultDate,
          enddate: defaultDate,
          targetdate: null,
          configCountReps: null,
          configCountSets: null,
          configDuration: null,
          configPreperationTimePatient: null,
          configTimeBetweenSets: null,
          configVideoFromTo: null,
          lastmodifiedBy: null,
          createdAt: defaultDate,
          createdBy: null,
          therapyPackage: undefined
        };
        break;
      case EditMode.edit:
        this.pageTitle = 'Event.Edit.Title';
        this.buttonTitle = 'Event.Edit.SaveChanges';
        this.isItemSelected = true;
        this.setInitialVideoParameters();
        break;
      case EditMode.extend:
        this.eventSeries.id = '';
        this.eventSeries.createdAt = moment().format('YYYY-MM-DD');
        this.eventSeries.startdate = this.today;
        this.eventSeries.enddate = this.today;
        this.pageTitle = 'Event.Extend.Title';
        this.buttonTitle = 'Event.Extend.Save';
        this.isItemSelected = true;
        this.isExtend = true;
        this.setInitialVideoParameters();
        break;
      default:
        console.log('No such mode exists!');
        break;
    }
  }

  ionViewDidEnter() {
    if(this.editMode === EditMode.edit) {
      this.hasStarted = this.getHasStartedStatus();
      this.hasEnded = this.getHasEndedStatus();
    }
  }

  get isCreateMode() {
    return this.editMode === EditMode.create;
  }

  get isEditMode() {
    return this.editMode === EditMode.edit;
  }

  /**
   * Checks, if the event series has already begun.
   * (i.e. the starting date is before today or an event of today has already been executed).
   *
   * @returns   True, if the event series has already begun
   */
  private getHasStartedStatus(): boolean {
    // Check if starting date of the event series is in the past
    if (this.eventSeries.startdate && this.eventSeries.startdate < this.today) {
      return true;
    }
    // Check if an event of the series has already been executed today
    if (this.eventSeries.startdate === this.today && Array.from(this.events).findIndex((ev) => ev.executed > 0) > -1) {
      return true;
    }
    return false;
  }

  /**
   * Checks, if there is an event that can still be executed.
   *
   * @returns   True, if such an event has been found
   */
  private getHasEndedStatus(): boolean {
    return Array.from(this.events).findIndex((ev) => ev.date > this.today || (ev.date === this.today && ev.executed === 0)) === -1;
  }

  /**
   * Set the video parameters to the ones in the event series.
   */
  public setInitialVideoParameters(): void {
    if (this.eventSeries.configVideoFromTo) {
      this.videoMoments = this.eventSeries.configVideoFromTo.split(',').map((timeframe: string) => {
        const [start, end] = timeframe.split('-');
        return { start: parseInt(start, 10), end: parseInt(end, 10) };
      });
    } else {
      if(this.isCreateMode) {
        this.addVideoParameters();
      }
    }
  }

  /**
   * Adds a new video recording.
   */
  public addVideoParameters(): void {
    if (this.videoMoments.length === 0) {
      this.videoMoments.push({ start: 0, end: 5 }); // TODO: video params per API
    } else {
      const previousEnd: number = this.videoMoments[this.videoMoments.length - 1].end;
      const newStart: number = previousEnd + 1;
      const newEnd: number = newStart + 5;
      this.videoMoments.push({ start: newStart, end: newEnd });
    }
  }

  public removeLastVideoMoment(): void {
      this.videoMoments.pop();
  }

  /**
   * Calculates the total duration of the exercise (without the preparation time).
   *
   * @returns   The duration of the exercise
   */
  public getExerciseDuration(eventSeries: EventSeries): number {
    if (!eventSeries.configCountSets || !eventSeries.configCountReps  || !eventSeries.configDuration
      || !eventSeries.configTimeBetweenSets) {
      return 0;
    }

    const setDuration = eventSeries.configCountSets * eventSeries.configCountReps * eventSeries.configDuration;
    const breakDuration = (eventSeries.configCountSets - 1) * eventSeries.configTimeBetweenSets;
    return setDuration + breakDuration;
  }

  /**
   * Get the duration of the audio file.
   *
   * @param audioid   Path to audio file
   * @returns         Promise for the duration of the audio file
   */
  public async getAudioDuration(audioid: string): Promise<any> {
    const cdnUrl: string = this.api.cdnUrl;
    return new Promise((resolve, reject) => {
      const audio = new Audio(cdnUrl + audioid);
      audio.preload = 'metadata';
      audio.onloadedmetadata = () => {
        audio && audio.duration ? resolve(audio.duration) : reject('Error resolving the audio duration');
      };
      audio.onerror = () => {
        reject('Error resolving the audio duration');
      };
    });
  }

  /**
   * Determine the duration of the selected event.
   * If the treatment is physiotherapy, calculate the duration by using the configuration parameters.
   * If the treatment is 'relax', use the audio duration.
   *
   * @param item: Item   The treatment name of the selected event
   * @returns               Promise for the duration of the exercise
   */
  private async getDurationOfEvent(item: Item): Promise<number> {
    this.hasAudioNotFoundError = false;
    const treatmentname = item.treatment.name;
    if (treatmentname === 'Physiotherapy') {
      return this.getExerciseDuration(this.eventSeries);
    }
    else if (treatmentname === 'Relax' || treatmentname === 'EvoRelax' || treatmentname === 'Feed') {
      if(!item.audioId) {
        this.hasAudioNotFoundError = true;
        return 0;
      }
      const duration = await this.getAudioDuration(item.audioId);
      if (!duration) {
        this.hasAudioNotFoundError = true;
        return 0;
      }
      const splittedDuration: string[] = duration.toString().split('.');
      return parseInt(splittedDuration[0], 10);
    }
    return 0;
  }

  /**
   * Checks, if the configurations of the eventseries are valid.
   *
   * @returns   True, if the configurations are valid
   */
  private checkEventConfigValidity(eventSeries: EventSeries): boolean {
    if (!eventSeries.name || !eventSeries.item) {
      this.errorMessage = this.translate.instant('Event.Error.Select');
      return false;
    }
    if (!eventSeries.startdate || !eventSeries.enddate) {
      this.errorMessage = this.translate.instant('Event.Error.Date');
      return false;
    }
    if (this.editMode !== EditMode.edit && eventSeries.startdate < this.today) {
      this.errorMessage = this.translate.instant('Event.Error.StartDate');
      return false;
    }
    if (eventSeries.startdate > eventSeries.enddate) {
      this.errorMessage = this.translate.instant('Event.Error.EndBeforeStart');
      return false;
    }
    if (!(eventSeries.perDay > 0 && eventSeries.frequenceDay > 0)) {
      this.errorMessage = this.translate.instant('Event.Error.Frequence');
      return false;
    }
    if (!(eventSeries.configCountSets||0 > 0 && eventSeries.configCountReps||0 > 0 && eventSeries.configTimeBetweenSets ||0 > 0 &&
eventSeries.configPreperationTimePatient||0 > 0 && eventSeries.configDuration||0 > 0)) {
      this.errorMessage = this.translate.instant('Event.Error.Parameters');
      return false;
    }
  return true;
  }

  /**
   * Checks, if the selected video timeframes are valid.
   *
   * @returns   True, if the timeframes are valid
   */
  private async checkVideoValidity(item: Item): Promise<boolean> {
    if (!item.treatment.name) {
      this.errorMessage = this.translate.instant('Event.Error.Treatment');
      console.error('No item or treatment found!', item);
      return false;
    }
    const durationExcercise: number = await this.getDurationOfEvent(item);
    if (this.hasAudioNotFoundError) {
      this.errorMessage = this.translate.instant('Event.Error.Audio');
      return false;
    }
    for (let i = 0; i < this.videoMoments.length; i++) {
      // Check validity of the video timeframes
      if(!this.videoMoments[i].start) this.videoMoments[i].start = 0;
      if(!this.videoMoments[i].end) this.videoMoments[i].end = 0;
      if (!(this.videoMoments[i].start >= 0 && this.videoMoments[i].end > 0)) {
        this.errorMessage = this.translate.instant('Event.Error.VideoParameters');
        return false;
      }
      if (this.videoMoments[i].start >= this.videoMoments[i].end) {
        this.errorMessage = this.translate.instant('Event.Error.VideoEndBeforeStart');
        return false;
      }
      if (this.videoMoments[i].start > durationExcercise || this.videoMoments[i].end > durationExcercise) {
        this.errorMessage = this.translate.instant('Event.Error.VideoDuration');
        return false;
      }
      if (this.videoMoments[i].end - this.videoMoments[i].start > 30) {
        this.errorMessage = this.translate.instant('Event.Error.VideoMaxLength');
        return false;
      }
      if (i > 0 && this.videoMoments[i].start <= this.videoMoments[i - 1].end) {
        this.errorMessage = this.translate.instant('Event.Error.VideoStartBeforeEnd');
        return false;
      }

      // Reduce the length of the video by 1, if it is set to the end of the exercise.
      if (this.videoMoments[i].end > durationExcercise - 1) {
        this.videoMoments[i].end = durationExcercise - 1;
        if (this.videoMoments[i].start >= this.videoMoments[i].end) {
          this.errorMessage = this.translate.instant('Event.Error.VideoStartIsEnd');
          return false;
        }
      }
    }

    // Remove the last video, if its duration has been reduced to 0 or less.
    if (this.videoMoments.length) {
      const lastVideoMoment = this.videoMoments[this.videoMoments.length - 1];
      if (lastVideoMoment.start >= lastVideoMoment.end) {
        this.videoMoments.pop();
      }
    }
    return true;
  }

  /**
   * Close the modal.
   */
  public cancel(): void {
    void this.modalCtrl.dismiss();
  }

  /**
   * Check the validity of the input and if valid, close the modal with the submitted data for the eventseries.
   */
  public async save(): Promise<void> {
    this.errorMessage = '';
    if(this.eventSeries.therapyPackage) {
      await this.modalCtrl.dismiss(this.eventSeries);
      return;
    }
    if (!this.eventSeries.item) {
      this.errorMessage = this.translate.instant('Event.Error.Select');
      console.error('No item or treatment found!', this.eventSeries);
      return;
    }
    if (this.eventSeries.item.treatment.name === 'Physiotherapy') {
      if (!this.checkEventConfigValidity(this.eventSeries) || ! await this.checkVideoValidity(this.eventSeries.item)) {
        return;
      }
    } else if (this.eventSeries.item.treatment.name === 'Relax' ||
              this.eventSeries.item.treatment.name === 'EvoRelax' ||
              this.eventSeries.item.treatment.name === 'Feed') {
      if (! await this.checkVideoValidity(this.eventSeries.item)) {
        return;
      }
    }

    if (this.eventSeries.item.treatment.name === 'Relax' ||
        this.eventSeries.item.treatment.name === 'EvoRelax' ||
        this.eventSeries.item.treatment.name === 'Feed' ||
        this.eventSeries.item.treatment.name === 'Physiotherapy') {
      // Create string of video timeframes to save in database (e.g. "0-5,6-10,11-15")
      let videoFromToString = '';
      for (let i = 0; i < this.videoMoments.length; i++) {
        videoFromToString += this.videoMoments[i].start + '-' + this.videoMoments[i].end;
        if (i !== (this.videoMoments.length - 1)) {
          videoFromToString += ',';
        }
      }
      this.eventSeries.configVideoFromTo = videoFromToString;
    } else {
      //console.log('Picking a non-physiotherapy/relax-item:', this.eventSeries);
    }

    // TODO: Workaround for: eventSeries.perDay should be a number, but is a string in the database

    await this.modalCtrl.dismiss(this.eventSeries);
  }

  /**
   * Open the modal for selecting the catalogue item.
   */
  async showCatalogue() {
    const modal = await this.modalCtrl.create({
      component: CataloguePage,
      id: 'catalogueModal',
      cssClass: 'catalog-modal',
      componentProps: {
        displayMode: DisplayMode.Modal,
      }
    });
    modal.onDidDismiss()
      .then((data) => {
        if (data.data !== undefined) {
          // Set the configs of the eventseries based on the selected catalogue item
          this.eventSeries.name = data.data.name;
          this.eventSeries.item = data.data;
          this.eventSeries.configCountReps = data.data.configCountReps;
          this.eventSeries.configCountSets = data.data.configCountSets;
          this.eventSeries.configDuration = data.data.configDuration;
          this.eventSeries.configPreperationTimePatient = data.data.configPreperationTimePatient;
          this.eventSeries.configTimeBetweenSets = data.data.configTimeBetweenSets;
          this.eventSeries.configVideoFromTo = data.data.configVideoFromTo;
          this.eventSeries.infos = data.data.configInfo;
          // Enable save button
          this.eventSeries.therapyPackage = undefined;
          this.isItemSelected = true;
          this.setInitialVideoParameters();

        }
      });
    return await modal.present();
  }

  /**
   * Delete all future events of the event series. Confirmation required.
   */
  async deleteEventSeries() {
    const modal = await this.modalCtrl.create({
      component: ConfirmationDialogPage,
      cssClass: 'confirmation-modal',
      componentProps: {
        text: 'Event.DeleteSeries',
      }
    });
    modal.onDidDismiss()
      .then((data) => {
        // Set the configs of the eventseries based on the selected catalogue item
        if (data.data) {
          this.eventsProvider.deleteEventSeries(this.eventSeries).subscribe(
            () => this.modalCtrl.dismiss(),
            (err) => {
              this.notification.presentToast(this.translate.instant('Event.Error.DeleteSeries'));
              console.error(err);
            }
          );
        }
      });
    return await modal.present();
  }

  /**
   * Open the modal for selecting the therapy package item.
   */
  async showPackage() {
    const modal = await this.modalCtrl.create({
      component: TherapyPackageCatalogue,
      id: 'packageModal',
      cssClass: 'catalog-modal',
      componentProps: {
        displayMode: DisplayMode.Modal,
        packages: this.therapyPackages
      }
    });
    modal.onDidDismiss()
      .then((data) => {
        // Set the configs of the eventseries based on the selected catalogue item
        if (data.data) {
          // Set the configs of the eventseries based on the selected catalogue item
          this.eventSeries.name = data.data.name;
          this.eventSeries.therapyPackage = data.data;
          this.eventSeries.item = null;
          // Enable save button
          this.isItemSelected = true;
        }
      });
    return await modal.present();
  }




  /**
   * Change the starting date of the event series to the selected date.
   * If the ending date of the series is currently set to an earlier date, it will also be changed.
   *
   * @param date  The new starting date
   */
  public changeStartDate(date: string): void {
    this.eventSeries.startdate = date;
    if (!this.eventSeries.enddate || date > this.eventSeries.enddate) {
      this.eventSeries.enddate = date;
    }
  }
}
