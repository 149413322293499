import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Patient} from '../models';
import {HttpErrorResponse} from '@angular/common/http';
import { Subscription } from 'rxjs';
import { GroupTherapyProvider } from '../services';
import { LoadingProvider } from '../services';
import { TranslateService } from '@ngx-translate/core';
import { GroupTherapyPatient } from '../models/api/group-therapy-patient.model';
import { NotificationProvider } from '../services';

/**
 * In the EventCreatePage the medical can choose an exercise
 * and set the configuration and dates for a new event series.
 */
@Component({
  selector: 'page-group-therapy-confirm-session',
  templateUrl: 'group-therapy-confirm-session.page.html',
  styleUrls: ['./group-therapy-confirm-session.page.scss']
})
export class GroupTherapyConfirmSessionPage implements OnInit {
  subscription: Subscription;

  public selectedPatientIds: Array<string> = [];
  public patientList: Array<GroupTherapyPatient> = [];
  //public notification: NotificationProvider;

  @Input()
  public appointmentId!: number;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly groupTherapyService: GroupTherapyProvider,
    private readonly loading: LoadingProvider,
    private readonly translate: TranslateService,
    private readonly notification: NotificationProvider
  ) {
    this.subscription = new Subscription();
    this.notification = notification;
  }

  ngOnInit(): void {
    /*if(this.navParams.get("selectedPatientIds")) {
      this.selectedPatientIds = this.navParams.get("selectedPatientIds").split(',');
    } else {
      this.selectedPatientIds = [];
    }*/
    this.subscription = this.groupTherapyService.getPatientsForAppointment(this.appointmentId).subscribe(
      (res) => {
        this.loading.dismissLoading();
        this.patientList = res;
      }, (err: HttpErrorResponse) => {
        this.loading.dismissLoading();
        console.error(err);
      }
    );
  }

  public selectPatient(patient: GroupTherapyPatient, index: any): void {
    if(this.selectedPatientIds.length && this.selectedPatientIds.indexOf(patient.id) > -1) {
      this.selectedPatientIds.splice(this.selectedPatientIds.indexOf(patient.id), 1);
    } else {
      this.selectedPatientIds.push(patient.id);
    }
  }

  public checkSelectedPatient(patientId: string) {
    if(this.selectedPatientIds.indexOf(patientId) > -1) {
      return true;
    } else {
      return false;
    }
  }

  async saveSelection() {
    this.loading.presentLoading(this.translate.instant('General.Cloud'));
    this.subscription = this.groupTherapyService.closeTherapySession(this.appointmentId, this.selectedPatientIds.toString()).subscribe(
      (res) => {
        this.loading.dismissLoading();
        if(res.request === 'ok') {
          this.modalCtrl.dismiss(true);
        }
      }, (err: HttpErrorResponse) => {
        this.notification.presentToast(this.translate.instant('GroupTherapy.AlreadySaved'));
        this.loading.dismissLoading();
        console.error(err);
      }
    );
  }

  public cancel(): void {
    this.modalCtrl.dismiss();
  }



}
