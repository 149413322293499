import { Component, OnInit, SecurityContext } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Internationalization } from './services/internationalization/internationalization';
import { TranslateService } from '@ngx-translate/core';
import { Theme } from './models/theme.model';
import { ThemeProvider } from './services/theme/theme';
import { DomSanitizer } from '@angular/platform-browser';
import { ENV } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  private theme: Theme = Theme.Dark;
  private themesUrl = window.location.origin + ENV.theme.baseUrl;
  darkResourceUrl  = this.sanitizer.bypassSecurityTrustResourceUrl(this.themesUrl + '_variables.dark.css');
  lightResourceUrl  = this.sanitizer.bypassSecurityTrustResourceUrl(this.themesUrl + '_variables.css');
  constructor(
    private readonly platform: Platform,
    private readonly translate: TranslateService,
    private readonly internationalization: Internationalization,
    private readonly themeService: ThemeProvider,
    private readonly sanitizer: DomSanitizer
  ) {
    this.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
    });
    this.initInternationalization();
  }

  ngOnInit(): void {
    this.themeService.theme.subscribe(theme=>this.theme = theme)
  }

  ionViewWillLeave(): void {
  }

  /**
   * Initialize default internationalization based on default loaded language
   */
  private initInternationalization() {
    this.internationalization.setInternationalization(this.translate.currentLang);
    //https://ionicframework.com/docs/angular/config#global-config
    //this.config.set('ios', 'backButtonText', this.translate.instant('General.Back'));
  }

  get darkMode() {
    return this.theme === Theme.Dark;
  }

  get lightMode() {
    return this.theme === Theme.Light;
  }

}
