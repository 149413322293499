import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CatalogueItemPreviewPage } from './catalogue-item-preview.page';

const routes: Routes = [
  {
    path: '',
    component: CatalogueItemPreviewPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CatalogueItemPreviewPageRoutingModule {}
