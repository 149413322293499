import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { EvoNeuroPageRoutingModule } from './evo-neuro-routing.module';
import { EvoNeuroPage } from './evo-neuro.page';
import { PipesModule } from '../pipes/pipes.module';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ComponentsModule } from '../components/components.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    PipesModule,
    TranslateModule,
    IonicModule,
    EvoNeuroPageRoutingModule,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgScrollbarModule,
    ComponentsModule
  ],
  declarations: [EvoNeuroPage]
})
export class EvoNeuroPageModule {}
