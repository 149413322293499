import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Api } from '../services/api/api';
import { TherapyUnitProvider } from '../services';
import { Event, EventSeries, EventSeriesSnapshot, Message, Result, TherapyUnit } from '../models';
import { ENV } from '../../environments/environment';
import { Duration } from '../services/duration/duration';
import { Router } from "@angular/router";
import {floor} from "lodash";

/**
 * The TherapyUnitDetailPage shows the exercises of the selected therapy unit
 * with their configurations, results and videos.
 */
@Component({
  selector: 'page-therapy-unit-detail',
  templateUrl: 'therapy-unit-detail.page.html',
  styleUrls: ['./therapy-unit-detail.page.scss']
})
export class TherapyUnitDetailPage implements OnInit {

  /** The selected therapy unit that has its details shown in this page. */
  @Input()
  public therapyUnit!: TherapyUnit;
  /** The sorted result Physio of given therapy unit. */
  public sortedResults: Result[] = [];
  /** The messages that were sent on the day of the therapy unit. */
  @Input()
  public messages!: Message[];
  /** The api path to the uploaded result videos. */
  private readonly uploadUrl: string;
  /** Disable button while result get checked. */
  private totalNumberOfDiaries = 0;
  public isCheckButtonDisabled: boolean = false;

  public sessionDuration: number = 0;

  constructor(
    private readonly therapyUnitProvider: TherapyUnitProvider,
    private readonly api: Api,
    private readonly router: Router,
    private readonly duration: Duration
  ) {
    this.uploadUrl = this.api.uploadUrl;
  }

  async ngOnInit() {
    this.sortedResults = this.sortResultsByDate(this.therapyUnit);

    this.sortedResults.map( async(r)  => {

      if (r.event) {

        if (r.event.eventSerie?.item?.treatment.name === 'NeuroNation') {
            let tDuration = this.getTimeDifferenceWithoutFormat(r.startTraining!, r.endTraining!);
            this.sessionDuration += tDuration;
        } else {
            let tDuration = await this.duration.getDuration(r?.event);
            this.sessionDuration += tDuration;
        }

      }

    })
    this.therapyUnitProvider.getTherapyUnitsForPatient()
      .subscribe((therapyUnits: TherapyUnit[]) => {
        if (therapyUnits && therapyUnits.length) {
          for (let i = 0; i < therapyUnits.length; i++) {
            if (therapyUnits[i].results && therapyUnits[i].results?.length) {
              this.totalNumberOfDiaries++;
            }
          }
        }
      });
  }


  /**
   * Get the combined folder and file path for a video
   *
   * @param filePath  The file path to the video
   * @returns         The combined folder and file path
   */
  public getVideoFilePath(filePath: string): string {
    if (!ENV.production) {
      // TODO: Only for local testing. Prevents 404.
      return 'assets/video/video-mock.webm';
    }
    return this.uploadUrl + filePath;
  }

  /**
   * Splits the string of video snapshots to an array.
   *
   * @param videoSnapshots  The video snapshots string
   * @returns               The array of video snapshots split by ","-separator
   */
  public splitVideoSnapshots(videoSnapshots: string): string[] {
    return videoSnapshots.split(',');
  }

  /**
   * Get the time of a date converted to local timezone.
   *
   * @param date  The date
   * @returns     The time converted to the local timezone
   */
  public getTimeOfDate(date: string): string {
    if (!date) {
      return '-';
    }
    return moment(date).local().format('HH:mm:ss');
  }

  /**
   * Get the time difference between to dates (used to calculate the duration of the training and exercise).
   *
   * @param startDate   The first date
   * @param endDate     The second date
   * @returns           The time difference in the format "HH:mm:ss" or nothing if start and end are not defined
   */
  public getTimeDifferenceBetweenTwoDates(startDate: string, endDate: string): string {
    let timeAsString = '-';
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const diff = end.diff(start);
      timeAsString = moment.utc(diff).format('HH:mm:ss');
    }
    return timeAsString;
  }

  public getTimeDifferenceWithoutFormat(startDate?: string, endDate?: string) {
    let diff = 0;
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const tDiff = end.diff(start);
      diff = floor(tDiff/1000);
    }
    return diff;
  }

  /**
   * Calculates the total duration of the exercise (without the preparation time).
   *
   * @param eventSerie    The event series of the exercise
   * @returns             The duration of the exercise
   */
  public getExerciseDuration(eventSerie: EventSeries | EventSeriesSnapshot | null): number {
    if(!eventSerie) return 0;

    if (!eventSerie.configCountSets || !eventSerie.configCountReps || !eventSerie.configDuration
      || !eventSerie.configCountSets || !eventSerie.configTimeBetweenSets) {
      return 0;
    }

    const setDuration = eventSerie.configCountSets * eventSerie.configCountReps * eventSerie.configDuration;
    const breakDuration = (eventSerie.configCountSets - 1) * eventSerie.configTimeBetweenSets;
    return setDuration + breakDuration;
  }

  /**
   * Sets a result as checked.
   *
   * @param resultId  The id of the exercise result
   * @param therapyUnit     The therapy unit that the exercise result belongs to
   */
  public checkResultByMedical(resultId: string, therapyUnit: TherapyUnit): void {
    this.isCheckButtonDisabled = true;

    this.therapyUnitProvider.setResultCheckedStateInDB(resultId).subscribe(
      (r: Result) => {
        this.therapyUnitProvider.setResultCheckedState(resultId, therapyUnit);
        this.isCheckButtonDisabled = false;
      }, (err) => {
        console.error(err);
        this.isCheckButtonDisabled = false;
      }
    );
  }

  public goToNeuroNationResult(event: Event | null) {
    if (event) {
      const navParams = {
        state: {
          event
        }
      };
      this.router.navigate(['/tabs/neuronation/'], navParams);
    }
  }


  /**
   * Sort result Physios of therapy unit by created at date
   *
   * @param therapyUnit the result physios of given therapy unit to be sorted
   */
  private sortResultsByDate(therapyUnit: TherapyUnit): Result[] {
    return therapyUnit && therapyUnit.results ? therapyUnit.results.sort((r1: Result, r2: Result) => {
      //@ts-ignore
      return new Date(r2.createdAt) - new Date(r1.createdAt);
    }) : [];
  }

  eventName(event: Event): string {
    return (event.eventSerieSnapshot?.name || event.eventSerie?.name) || '';
  }

  eventPerDay(event: Event): string {
    const perDay = (event.eventSerieSnapshot?.perDay || event.eventSerie?.perDay) || 0;
    if(perDay > 1) {
      return `(${(event.counterPerDay || 0) + 1}/${perDay})`;
    }
    return '';
  }

}
