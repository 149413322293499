import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap} from 'rxjs/operators'
import { NeuronationDataCacheService } from "../cache/neuronation-cache";
import { HttpClient } from '@angular/common/http';
import { ENV } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NeuronationDataService {
    url: string =  ENV.apiUrl;
  readonly endpoint = this.url + '/neuroNation/getExerciseTitles';

    constructor(private http: HttpClient,
                private neuroDataCache: NeuronationDataCacheService) {
    }
    getData(): Observable<any> {
          let data$ = this.neuroDataCache.getValue('title');

          if (!data$) {
            const req = this.http.get(this.endpoint);
            this.http.get(this.endpoint).pipe(
              tap(console.log),
              map((response: any) => {
                data$ = response;
              }),
              shareReplay(1)
            ).subscribe();
            this.neuroDataCache.setValue(data$, 'title')
          }
          return data$;
    }


}
