import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { io, Socket } from 'socket.io-client';

import { Api } from '../api/api';
import { Medical, Message } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class MessageProvider {

  /** The latest message that was recieved as a BehaviorSubject for manually assigning new values. */
  private message$$: BehaviorSubject<Message | null> = new BehaviorSubject<Message | null>(null);
  /** The latest message that was recieved as an Observable for subscriptions in components. */
  public message$: Observable<Message | null> = this.message$$.asObservable();

  /** The list of unread messages as a BehaviorSubject for manually assigning new values. */
  private unreadMessages$$: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  /** The list of unread messages as an Observable for subscriptions in components. */
  public unreadMessages$: Observable<Message[]> = this.unreadMessages$$.asObservable();

  /** The socket connection. */
  private socket: Socket | null = null;

  constructor(
    private readonly api: Api,
  ) {
  }


  connect(user: Medical, token: string) {
    const loggedInUser = user;
    let path;
    if (this.api.useSso) {
        path = '/' + localStorage.getItem("api_path") + '/socket.io/';
    } else {
        path = '/socket.io/';
    }

    // connect to io socket with jwt
    this.socket = io(this.api.socketUrl, {
      path: path,
      transportOptions: {
        polling: {
          extraHeaders: { Authorization: 'Bearer ' + token }
        }
      }
    });

    this.socket.on('unauthorized', (error) => {
      if (error.data.type === 'UnauthorizedError' || error.data.code === 'invalid_token') {
        // redirect user to login page perhaps?
        //console.log('User token has expired');
      }
    });

    // listen to message and set new message to Behaviour Subject
    this.socket.on('message', (message: Message) => {
      // if message is from logged in user --> dont set unread message
      if (message && message.idSender !== loggedInUser.id) {
        this.setUnreadMessage(message);
      }
      this.message$$.next(message);
    });


    // get all unread messages for logged in user and push in unread message behaviour subject if sender !== medical

    this.api.get('message/getUnreadMessagesForMedicalById', { id: loggedInUser.id })
      .subscribe((res: Array<Message>) => {
        this.unreadMessages$$.next(res);
      }, () => {
        console.error('Error fetching Unread Messages for user', loggedInUser.id);
    });
  }

  /**
   * Send message to socket stream.
   *
   * @param msg   The message being sent
   */
  public sendMsg(msg: Partial<Message>): void {
    this.socket?.emit('message', msg);
  }

  /**
   * Get an Observable of the latest message.
   *
   * @returns   Observable of the latest message
   */
  public getCurrentMessage(): Observable<Message|null> {
    return this.message$;
  }

  /**
   * Add a message to the list of unread messages.
   *
   * @param message   A new unread message
   */
  private setUnreadMessage(message: Message): void {
    let unread: Message[] = this.unreadMessages$$.value;
    if (!unread) {
      unread = [];
    }
    unread.push(message);
    this.unreadMessages$$.next(unread);
  }

  /**
   * Get the list of unread messages as an Observable.
   *
   * @returns   Observable of the unread messages
   */
  public getUnreadMessage(): Observable<Message[]> {
    return this.unreadMessages$;
  }

  /**
   * Join the socket room for a new patient
   *
   * @param patientid   The id of the new patient
   */
  public joinRoomWhenCreatingNewPatient(patientid: string): void {
    this.socket?.emit('joinRoomWhenCreatingNewPatient', patientid);
  }

  /**
   * Sends a request to mark the messages of a patient as read.
   *
   * @param medicalid   The id of the current user
   * @param patientid   The id of the patient whose messages are being read
   */
  public markMessagesForMedicalasReadInDB(medicalid: string, patientid: string): void {
    this.api.post('message/setMessagesAsReadForMedical', { patientid, medicalid })
      .subscribe(
        () => {
          this.markMessagesOfPatientAsRead(patientid);
        }, () => {
          console.error('Error fetching Unread Messages for user');
        }
      );
  }

  /**
   * Marks the messages of a patient in the list of unread messages as read.
   *
   * @param patientid   The id of the patient
   */
  private markMessagesOfPatientAsRead(patientid: string): void {
    const unread: Message[] = this.unreadMessages$$.value;
    if (unread && unread.length) {
      const messages: Message[] = unread.filter((msg: Message) => msg.idSender !== patientid);
      this.unreadMessages$$.next(messages);
    }
  }

  /**
   * Get all messages for a specified user.
   *
   * @param id  The user id
   * @returns   Observable of the get-request being sent
   */
  public getAllMessagesForId(id: string): Observable<Message[]> {
    return this.api.get('message/getAllMessagesForSenderAndReceiverById', { id });
  }

  /**
   * Sends a new message.
   *
   * @param idSender      The id of the medical sending the message
   * @param nameSender    The name of the medical
   * @param idReceiver    The id of the patient recieving the message
   * @param type          The type of the message
   * @param content       The content of the message
   * @param fileName      The filename (for media files)
   * @returns             Observable of the post-request being sent
   */
  public postNewMessage(idSender: string, nameSender: string, idReceiver: string, type: string, content: string, fileName: string): Observable<any> {
    return this.api.post('message/createNewMessage', {
      message: content,
      filename: '-',
      idsender: idSender,
      idreceiver: idReceiver,
      namesender: nameSender,
      type: type,
      notify: true
    });
  }

  public disconnect() {
    if(!this.socket) {
      return;
    }
    this.socket.disconnect();
    this.socket = null;
  }

}
