import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Api } from '../api/api';
import { User } from '../user/user';
import { Patient, PatientWithAdditionalInformation } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class PatientsProvider {

    /** Patient list of the current medical as a BehaviorSubject for manually assigning new values. */
    private patientList$$: BehaviorSubject<PatientWithAdditionalInformation[]> =
      new BehaviorSubject<PatientWithAdditionalInformation[]>([]);
    /** Patient list of the current medical as an Observable for subscriptions in components. */
    private patientList$: Observable<PatientWithAdditionalInformation[]> = this.patientList$$.asObservable();

    constructor(
        public api: Api,
        private user: User
    ) { }

    /**
     * Initialize the patient list.
     */
    public init(): void {
        this.emptyPatientList();
        this.loadPatientList().subscribe(
            () => {},
            (err) => console.error(err)
        )
    }

    /**
     * Load the patient list by sending a request to get the patients for the current medical.
     *
     * @returns     Http-Response is the list of patients
     */
    public loadPatientList(): Observable<PatientWithAdditionalInformation[]> {
        return this.api.get<PatientWithAdditionalInformation[]>('patient/getAdditionalInformationsByMedicalId',
          { medicalid: this.user.user!.id }).pipe(
            tap((res) => {
                if (res) {
                    // Save patients on client
                    this.setPatientList(res);
                }
                else {
                    console.error('No patient data retrieved', res);
                }
            })
        );
    }

    /**
     * Get an Observable of the stored patient list.
     *
     * @returns     The stored patients
     */
    public getPatientList(): Observable<PatientWithAdditionalInformation[]> {
      return this.patientList$;
    }

    /**
     * Send request to delete a patient.
     *
     * @param patient   The patient to delete
     * @returns         Http-Response if deletion was successfull
     */
    public deletePatient(patient: Patient): Observable<any> {
        type PatientPlus = PatientWithAdditionalInformation;
        return this.api.post('patient/deletePatient', { id: patient.id }).pipe(
            tap(() => {
                // Remove patient on client
                const curPatientList = this.patientList$$.getValue();
                _.remove(curPatientList, (p: PatientPlus) => p.patient.id === patient.id);
                this.setPatientList(curPatientList);
            })
        );
    }

    /**
     * Send request to create a new patient.
     *
     * @param patient   The patient to create
     * @param reportData
     * @returns         Http-Response is the created patient
     */
    public addPatient(patient: Partial<Patient>, reportData): Observable<Patient> {
        return this.api.post('patient/createNewPatient', { medicalid: this.user.user!.id, patient , reportData}).pipe(
            tap((res: any) => {
                if (res) {
                    // Add patient on client
                    const patient: PatientWithAdditionalInformation = {
                        patient: res,
                        counterTEFacility: 0,
                        counterTEHome: 0,
                        isEveryTEChecked: true
                    }
                    const curPatientList = this.patientList$$.getValue();
                    curPatientList.push(patient);
                    this.setPatientList(curPatientList);
                }
                else {
                    console.error('No patient data retrieved', res);
                }
            }, (error) => {
                if (error.status === 409) {
                    return error;
                } else {

                }
            })
        );
    }

    /**
     * Send request to update the patient.
     *
     * @param patient   The patient to update
     * @param reportData
     * @returns         Http-Response is the updated patient
     */
    public editPatient(patient: Partial<Patient>, reportData): Observable<Patient> {
        return this.api.post('patient/updateExistingPatient', { medicalid: this.user.user!.id, patient: patient, reportData: reportData }).pipe(
            tap((res: any) => {
              if (res) {
                  // Change patient on client
                  const curPatientList = this.patientList$$.getValue();
                  const patientInList = curPatientList.find((p: PatientWithAdditionalInformation) => p.patient.id === res.id);
                  if(!patientInList) return;
                  const patient: PatientWithAdditionalInformation = {
                    patient: res,
                    counterTEFacility: patientInList.counterTEFacility,
                    counterTEHome: patientInList.counterTEHome,
                    isEveryTEChecked: patientInList.isEveryTEChecked,
                    lastTEdate: patientInList.lastTEdate
                  }
                  const index: number = curPatientList.findIndex((p: PatientWithAdditionalInformation) => p.patient.id === res.id);
                  curPatientList.splice(index, 1, patient);
                  this.setPatientList(curPatientList);
              }
               else {
                console.error('No patient data retrieved', res);
               }
            })
        );
    }

    /**
     * Send request to reset the password of a patient.
     *
     * @param patient   The patient
     * @param pw        The new password
     * @returns         Http-Response if deletion was successfull
     */
    public resetPasswordForPatient(patient: Patient, pw: string): Observable<any> {
        return this.api.post('patient/resetPassword', { patientid: patient.id, password: pw });
    }

    public setTherapyUnitAsCheckedForPatient(patientid: string, isHome: boolean): void {
      const patients: PatientWithAdditionalInformation[] = this.patientList$$.value;
      const patientIndex: number = patients.findIndex((p: PatientWithAdditionalInformation) => p.patient.id === patientid);
      const foundPatient = patients.find((p: PatientWithAdditionalInformation) => p.patient.id === patientid);
      if(!foundPatient) return;
      isHome ? foundPatient.counterTEHome ++ : foundPatient.counterTEFacility ++;
      patients.splice(patientIndex, 1, foundPatient);
      this.patientList$$.next(patients.slice());

    }

  public sendEmailConfirmation(email: string, patientId: string) {
    return this.api.post('patient/sendEmailConfirmation', { email: email, patientId: patientId, patient: false});
  }

    /**
     * Set the stored patient list.
     *
     * @param patientList   The patient list
     */
    private setPatientList(patientList: PatientWithAdditionalInformation[]): void {
      this.patientList$$.next(patientList);
    }

    /**
     * Empty the stored patient list.
     */
    private emptyPatientList(): void {
      this.patientList$$.next([]);
    }
}
