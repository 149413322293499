import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CatalogueItemPreviewPage } from '../catalogue-item-preview/catalogue-item-preview.page';
import { CatalogueProvider } from '../services';
import { Catalogue, Item } from '../models';
import { DisplayMode } from '../models/display-mode';
import { Router } from "@angular/router";

/**
 * The CataloguePage shown in its respective tab
 * or when selecting an exercise for a new event series.
 * It contains a treeview of the catalogue items.
 */
@Component({
  selector: 'page-catalogue',
  templateUrl: 'catalogue.page.html',
  styleUrls: ['catalogue.page.scss']
})
export class CataloguePage {

  /** Display mode is "tab" or "modal". */
  @Input()
  public displayMode: DisplayMode = DisplayMode.Tab;
  /** The Tree of catalogue items in the form of "catalogue => category => item". */
  public catalogues: Catalogue[] = [];
  /** The current result list of a search for catalogue items. */
  public searchResult: Item[] = [];
  /** The item list that is currently displayed (Catalogue[] | Item[]) */
  public activeList: any[] = [];
  /** Array to help navigate the categories */
  public breadcrumbs: Catalogue[] = [];

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly router: Router,
    private readonly catalogueProvider: CatalogueProvider
  ) { }

  ionViewDidEnter(): void {
    this.catalogues = this.catalogueProvider.getCatalogues();
    this.activeList = this.catalogues;
  }

  /**
   * Search for catalogue items containing the entered term in their name.
   *
   * @param event   The input event of the searchbar
   */
  public searchItems(event: any): void {
    this.searchResult = this.catalogueProvider.searchCatalogueItems(event.target.value);
  }

  /**
   * Close the modal.
   */
  public cancel(): void {
    void this.modalCtrl.dismiss();
  }

  select(item: Catalogue | Item) {
    if ('level' in item) {
      this.activeList = item.items;
      this.breadcrumbs.push(item);
    }
    else {
      void this.chooseItem(item);
    }
  }

  navigateToCategory(index: number, item: Catalogue) {
    if (index === this.breadcrumbs.length - 1) return;
    if (index === 0 && this.breadcrumbs.length > 1) {
      this.breadcrumbs.splice(index + 1, 1);
    }

    this.activeList = item.items;
  }

  backToMainCategory() {
    this.activeList = this.catalogues;
    this.breadcrumbs = [];
  }

  /**
   * Opens the preview of the selected catalogue item.
   * If the catalgue is shown in a modal and the user confirms the choice in the preview, close the modal.
   *
   * @param item  The selected catalogue item
   */
  async chooseItem(item: Item) {
    const modal = await this.modalCtrl.create({
      component: CatalogueItemPreviewPage,
      componentProps: {
        item: item,
        displayMode: this.displayMode
      }
    });
    modal.onDidDismiss()
      .then((data) => {
        if (this.displayMode === DisplayMode.Tab) {
          return;
          // Selected item choice was confirmed in preview. Close the catalogue modal page.
        } else if (data.data) {
          this.modalCtrl.dismiss(data.data, undefined, 'catalogueModal');
        }
      });
    return await modal.present();
  }

  goBack() {
    const catTreeLength = this.breadcrumbs.length;
    if(catTreeLength-2 < 0) {
      this.backToMainCategory();
      return;
    }
    this.navigateToCategory(catTreeLength-2, this.breadcrumbs[catTreeLength-2]);
  }

  get modalDisplay() {
    return this.displayMode === DisplayMode.Modal;
  }

  goToTherapyPackages() {
    void this.router.navigate(['tabs/therapy-packages'])
  }
}
