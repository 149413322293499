import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourMinuteSeconds',
})
export class HourMinuteSecondsPipe implements PipeTransform {

  transform(value: number): string {
    let hours = Math.floor((value/3600));
    const minutes: number = Math.floor(value % 3600 / 60);
    const seconds = Math.floor(value % 3600 % 60);
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0': ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }
}
