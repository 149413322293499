import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'localeDateTimeUnix'})
export class LocaleDateTimeUnixPipe implements PipeTransform {
  transform(value: number, format = 'LLL'): string {
    const jsValue = value * 1000;
    return moment(jsValue).format(format);
  }
}
