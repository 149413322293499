import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TherapyPackage } from '../models';
import { DisplayMode } from '../models/display-mode';
import { PackageItemPreviewPage } from '../package-item-preview/package-item-preview.page';

/**
 * The CataloguePage shown in its respective tab
 * or when selecting an exercise for a new event series.
 * It contains a treeview of the catalogue items.
 */
@Component({
  selector: 'package-catalogue',
  templateUrl: 'package-catalogue.page.html',
  styleUrls: ['./package-catalogue.page.scss']
})
export class TherapyPackageCatalogue {

  /** The Tree of catalogue items in the form of "catalogue => category => item". */
  @Input()
  public packages: TherapyPackage[] = [];
  /** Display mode is "tab" or "modal". */
  @Input()
  public displayMode = DisplayMode.Modal;

  constructor(
    private readonly modalCtrl: ModalController
  ) {}

  ionViewDidEnter(): void {

  }

  /**
   * Close the modal.
   */
  public cancel(): void {
    this.modalCtrl.dismiss();
  }

  async choosePackage(therapyPackage: TherapyPackage) {
    const modal = await this.modalCtrl.create({
      component: PackageItemPreviewPage,
      componentProps: {
        therapyPackage: therapyPackage,
        displayMode: this.displayMode
      }
    });
    modal.onDidDismiss()
      .then((data) => {
        // Set the configs of the eventseries based on the selected catalogue item
        if (data.data) {
          this.modalCtrl.dismiss(therapyPackage, undefined, 'packageModal');
          if (this.displayMode === DisplayMode.Tab) {
            return;
          }
        }
      });
    return await modal.present();
  }

  get modalDisplay() {
    return this.displayMode === DisplayMode.Modal;
  }
}
