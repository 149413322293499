import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { Api } from '../api/api';
import { User } from '../user/user';
import { Catalogue, Item } from '../../models';
import { ApiCatalogue } from 'src/app/models/api/catalogue.model';

@Injectable({
  providedIn: 'root'
})
export class CatalogueProvider {

    /** The list of all catalogue items for the current account, stored for faster search. */
    private catalogueItemList: Item[] = [];
    /** The list of catalogue trees being displayed it in the page. */
    private catalogueList: Catalogue[] = [];

    constructor(
        private api: Api,
        private user: User
    ) { }

    /**
     * Initialize the catalogue lists.
     */
    public init(): void {
        this.catalogueItemList = [];
        this.catalogueList = [];
        this.loadCatalogues().subscribe(
            () => { },
            (err) => console.error(err)
        );
    }

    /**
     * Load the catalogues by sending a request to get the catalogues for the current account.
     *
     * @returns     Http-Response is the list of catalogues
     */
    public loadCatalogues(): Observable<ApiCatalogue[]> {
        return this.api.get<ApiCatalogue[]>('catalogue/getCatalogueByAccountId', { accountid: this.user.user!.account.id }).pipe(
            tap((res) => {
                if (res) {
                    // Save catalogues on client
                    this.setCatalogues(res);
                }
                else {
                    console.error('No catalogue items retrieved', res);
                }
            })
        );
    }

    /**
     * Set the list of catalogue trees and catalogue items.
     * @param catalogues    The list of catalogues
     */
    private setCatalogues(catalogues: ApiCatalogue[]): void {
        let items: Item[] = [];
        const trees: Catalogue[] = [];

        for (let i = 0; i < catalogues.length; i++) {
            const catalogueItems = catalogues[i].items.map(item => this.cleanItemProperties(item, catalogues[i].name));

            // Save the items of this catalogue in the catalogue item list
            items = items.concat(catalogueItems);

            // Create the catalogue tree for this catalogue
            const catalogueCategories = _.chain(catalogueItems)
                .groupBy('category')
                .map((value: Item[], key: string) => {
                    const category: Catalogue = {
                        name: key,
                        items: value,
                        level: 2,
                        description: ''
                    }
                    return category;
                }).value();
            const curTree: Catalogue = {
                name: catalogues[i].name,
                description: catalogues[i].description,
                items: catalogueCategories,
                level: 1
            }
            // Add this tree to the list of catalogue tree
            trees.push(curTree);
        }

        this.catalogueItemList = items;
        this.catalogueList = trees;
    }

    /**
     * Set the category for items that do not have one defined
     * and the name of the catalogue that the item is from.
     * @param item              The item that is being checked
     * @param catalogueName     The name of the catalogue that contains the item
     * @returns                 The cleaned item
     */
    private cleanItemProperties(item: Item, catalogueName: string): Item {
        // Set the category of the items with invalid category names to the default.
        const defaultCategory = "Allgemein";
        if (!item.category) {
            item.category = defaultCategory;
        }
        item.category = item.category.trim().replace(/["']/g, "");
        if (!item.category || item.category == "null") {
            item.category = defaultCategory;
        }

        // Set catalogue name for displaying it in the results of the search.
        item.catalogueName = catalogueName;

        return item;
    }

    /**
     * Get the stored list of catalogue trees.
     * @returns     The stored catalogue trees
     */
    public getCatalogues(): Catalogue[] {
        return this.catalogueList;
    }

  /**
   * Search for catalogue items containing the search term in their name.
   * @param searchTerm  The search term
   * @returns           The found items
   */
    public searchCatalogueItems(searchTerm: string): Item[] {
        searchTerm = searchTerm ? searchTerm : "";
        searchTerm = searchTerm.trim();
        if (!searchTerm) {
            return [];
        }
        searchTerm = searchTerm.toLowerCase();
        return this.catalogueItemList.filter((item) => item.name.toLowerCase().indexOf(searchTerm) >= 0);
    }

}
