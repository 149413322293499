import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Api } from '../api/api';
import {Result, TherapyUnit} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class TherapyUnitProvider {

  private therapyUnits$$: BehaviorSubject<TherapyUnit[]> = new BehaviorSubject<TherapyUnit[]>([]);
  public therapyUnits$: Observable<TherapyUnit[]> = this.therapyUnits$$.asObservable();

  constructor(
    private api: Api
  ) { }

  public fetchTherapyUnitsForSpecificPatient(patientid: string) {
    this.api.get('therapyUnit/getAllTherapyUnitsForPatient', {patientid}).subscribe((therapyUnits: TherapyUnit[]) => {
      this.therapyUnits$$.next(therapyUnits);
    });
  }

  public getTherapyUnitsForPatient(): Observable<TherapyUnit[]> {
    return this.therapyUnits$;
  }

  public clear(): void {
    this.therapyUnits$$.next([]);
  }

  //todo: observable type
  public setResultCheckedStateInDB(resultId: string): Observable<any> {
    return this.api.post('result/updateResultCheckedState', {id: resultId});
  }

  public setResultCheckedState(resultId: string, therapyUnit: TherapyUnit): void {
    const therapyUnits: TherapyUnit[] = this.therapyUnits$$.value;
    const toUpdatedResult = therapyUnit.results?.find((r: Result) => r.id === resultId);
    if(!toUpdatedResult) return;
    toUpdatedResult.checked = 1;
    therapyUnit.results?.splice(therapyUnit.results.findIndex((r: Result) => r.id === resultId), 1, toUpdatedResult);
    if (this.isTherapyChecked(therapyUnit)) {
      therapyUnit.checkedByMedical = 1;
    }
    therapyUnits.splice(therapyUnits.findIndex((tu: TherapyUnit) => tu.id === therapyUnit.id), 1, therapyUnit);
    this.therapyUnits$$.next(therapyUnits.slice());
  }

  private isTherapyChecked(therapyUnit: TherapyUnit): boolean {
    if(!therapyUnit.results) return false;
    const unCheckedResultsInTherapyUnit = therapyUnit.results.filter((r: Result) => !r.checked);
    return unCheckedResultsInTherapyUnit.length === 0;
  }

}
