import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TherapyPackage } from "../../models";
import { Api } from '../api/api';
import { User } from '../user/user';

@Injectable({
  providedIn: 'root'
})
export class PackageProvider {

  private data$$: BehaviorSubject<TherapyPackage[]> = new BehaviorSubject<TherapyPackage[]>([]);

  public data$: Observable<TherapyPackage[]> = this.data$$.asObservable();

  public packages;

  constructor(
    public http: HttpClient,
    private api: Api,
    private user: User
  ) {
    this.fetchData()
  }

  public fetchData(): void {
    /*
    if (this.user.user?.account?.therapypackages) {
      this.data$$.next(this.user.user?.account?.therapypackages);
    }
    */

    this.api.get('therapy-package/getTherapyPackageByAccountId', { accountid: this.user.user!.account.id }).subscribe((object: TherapyPackage[]) => {
      if (!object) {
        return;
      }
      this.packages = object;
      this.data$$.next(object)
    });

  }

  public getData(): Observable<TherapyPackage[]> {
    return this.data$;
  }

  public getPackages() {
    return this.packages;
  }

}
