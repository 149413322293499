import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  templateUrl: 'calendar-header.html',
  styleUrls: ['calendar-header.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarHeaderComponent<Moment> implements OnInit, OnDestroy {
  private _destroyed = new Subject<void>();
  months: { value: number, label: string }[] = [];
  years: number[] = [];
  monthsOpen = false;
  yearsOpen = false;

  @ViewChild('scrollbar') scrollRef!: NgScrollbar;

  constructor(
    private readonly _calendar: MatCalendar<moment.Moment>,
    private readonly _dateAdapter: DateAdapter<moment.Moment>,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.months = this._dateAdapter.getMonthNames("long").map((monthName, index) => {
      return { value: index, label: monthName }
    });
    const minYear = this._calendar.minDate!.year();
    const maxYear = this._calendar.maxDate!.year();
    this.years = this.range(minYear, maxYear);
  }

  get month() {
    return this.selected.month();
  }

  get year() {
    return this.selected.year();
  }

  get monthLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, 'MMMM');
  }

  get yearLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, 'YYYY');
  }

  ngOnInit(): void {
    this._calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => this.cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  openMonths() {
    this.monthsOpen = true;
  }

  closeMonths() {
    this.monthsOpen = false;
  }

  openYears() {
    this.yearsOpen = true;
    this.scrollRef.updated.subscribe(()=>{
      const DOM = document.getElementById('y' + this._calendar.activeDate.year());
      if(DOM) DOM.scrollIntoView({behavior: "smooth"});
    });
  }

  closeYears() {
    this.yearsOpen = false;
  }

  selectMonth(month: number) {
    if (!this.isMonthValid(month)) {
      return;
    }
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate.month(month), 0);
    this.closeMonths();
  }

  selectYear(year: number) {
    if (!this.isYearValid(year)) {
      return;
    }
    this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate.year(year), 0);
    this.closeYears();
  }

  range(start: number, end: number) {
    return Array(end - start + 1).fill(0).map((_, idx) => start + idx)
  }

  isMonthValid(month: number) {
    const date = moment([this.selected.year(), month, 1]);
    
    if(this._calendar.maxDate) {
      const max = this._calendar.maxDate.clone().endOf('month');
      if(date > max) {
        return false;
      }
    }

    if(this._calendar.minDate) {
      const min = this._calendar.minDate.clone().startOf('month');
      if(date < min) {
        return false;
      }
    }
    return true;
  }

  isYearValid(year: number) {
    if(this._calendar.maxDate && year > this._calendar.maxDate.year()) {
      return false;
    }
    if(this._calendar.minDate && year < this._calendar.minDate.year()) {
      return false;
    }
    return true;
  }

  get selected() {
    return this._calendar.activeDate;
  }

}
