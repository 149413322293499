import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { User } from '../user/user';

@Injectable({
    providedIn: 'root'
})
export class Internationalization {

    constructor(
        private readonly translate: TranslateService,
        private readonly dateAdapter: DateAdapter<any>,
        private user: User
    ) { }

    async setInternationalization(code: string) {
      if (code && this.translate.getLangs().indexOf(code) !== -1) {
        if (this.user.user && this.user.user.lang !== code) {
          await (await this.user.changeLanguage(this.user.user.id, code)).subscribe((res) => console.log('language set'));
        }
        this.translate.use(code);
        moment.locale(code);
        this.dateAdapter.setLocale(code);
      } else {
        console.error('Cannot find account language in application', code);
      }
    }
}
