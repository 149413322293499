import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { TherapyUnitDetailPageRoutingModule } from './therapy-unit-detail-routing.module';
import { TherapyUnitDetailPage } from './therapy-unit-detail.page';
import { PipesModule } from '../pipes/pipes.module';
import { CommonModule} from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  imports: [
    TranslateModule,
    PipesModule,
    IonicModule,
    CommonModule,
    TherapyUnitDetailPageRoutingModule,
    NgScrollbarModule
  ],
  declarations: [TherapyUnitDetailPage]
})
export class TherapyUnitDetailPageModule {}
