import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Theme} from 'src/app/models/theme.model';
import {ENV} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeProvider {
  private _theme = new BehaviorSubject<Theme>(ENV.theme.default);
  enableSwitch = ENV.theme.enableSwitch;

  switchTheme(nextTheme?: Theme) {
    if(!this.enableSwitch) return;
    if(!nextTheme) {
      const current = this._theme.getValue();
      if(current === Theme.Dark) nextTheme = Theme.Light;
      if(current === Theme.Light) nextTheme = Theme.Dark;
    }
    this._theme.next(nextTheme!);
  }

  setUseDarkTheme(useDarkTheme: boolean) {
      if (useDarkTheme) {
        this._theme.next(Theme.Dark);
      } else {
        this._theme.next(Theme.Light);
      }
  }

  get theme() {
    return this._theme.asObservable();
  }

}
