import {firstValueFrom, Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ENV} from '../../../environments/environment';
import {Storage} from '../storage/storage';
import 'rxjs/operators';

/**
 * Api is a generic REST Api handler. Checkout Branches for Live/Dev-Server not working: use gitattributes instead
 */
@Injectable({
  providedIn: 'root'
})
export class Api {
  url: string =  ENV.apiUrl;
  saveUrl: string = ENV.apiUrl;
  baseUrl: string = ENV.baseUrl;
  /** For load video, audio, user.account.logo, catalogue-item images */
  cdnUrl: string = ENV.cdnUrl;
  /** Socket connection */
  socketUrl: string = ENV.socketUrl;
  /** Upload url for therapy unit videos */
  uploadUrl: string = ENV.uploadUrl;
  prmUrl: string = ENV.prmUrl;
  ssoUrl: string = ENV.ssoUrl;
  useSso: boolean = ENV.useSso;
  ssoUser: string = ENV.ssoUser;
  ssoPassword: string = ENV.ssoPassword;
  icdServer: string = ENV.icdServer;
  apiPath = ''; //TODO make check and use it
  constructor(
    public http: HttpClient,
    private readonly storage: Storage
  ) { }

  get<T>(endpoint: string, params?: any, reqOpts?: any): Observable<any> {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        withCredentials: true  // WITH CREDENTIALS? => yes for passport req.isAuthenticated / CORS
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      //reqOpts.params = reqOpts.params.append(params.accountid.key, params.accountid);
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }
    return  this.http.get<T>(this.url + '/' + endpoint, reqOpts);
  }

  post<T = any>(endpoint: string, body: any) {
    const headers = new HttpHeaders();
    headers.append('Charset', 'utf-8');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT, DELETE');
    headers.append(
      'Access-Control-Allow-Headers',
      'Content-Type, Content-Range, Content-Disposition, Content-Description, Access-Control-Allow-Origin, charset, evomedia');
    return this.http.post<T>(this.url + '/' + endpoint, body, { headers, observe: 'body', responseType: 'json' ,withCredentials: true});
  }

  public async ssoPost(endpoint: string, body: any) {
    const path = await firstValueFrom(this.http.post(this.ssoUrl + '/' + endpoint,
      body,
      { headers: { 'Content-Type':'application/json','Authorization':'Basic ' + btoa(this.ssoUser + ":" + this.ssoPassword) } }
    ));
    this.url = this.baseUrl + '/' + path + '/api/v1';
    this.uploadUrl = this.baseUrl + '/' + path + '/uploads/';
    this.socketUrl = this.baseUrl + '/' + path + '/socket.io/';

    this.storage.setApiPath(path);
    this.storage.setSocketPath(path);
  }

  public resetUrl() {
    this.url = this.saveUrl;
  }

  public async getIcd(endpoint: string, data: any) {
     let body = { data: data }
    return this.http.post(this.icdServer + '/api/v1/' + endpoint,
      body,
      {headers: {'Content-Type': 'application/json'}}).toPromise();
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + '/' + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any) {
    return this.http.delete(this.url + '/' + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.url + '/' + endpoint, body, reqOpts);
  }

}
